@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@font-face {
  font-family: 'Karla-Regular';
  src: local('Karla-Regular'), url(./Assets/fonts/Karla-Regular.ttf) format('truetype');
}
/* has to be scroll, not auto */
.root{
  -webkit-overflow-scrolling: touch;
}

div#root{
  height: 100vh;
}

html, body{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

code {
  font-family: 'Karla', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper{
  overflow-x:hidden;
  background-image: url(./Assets/abn-bg-new.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overscroll-behavior: contain;
  overflow: hidden;
  
}

.wrapper{
  background-image: none;
  display: block;
  position: absolute;
  right: 0;
  left:0;
  top:80px;
  bottom: 68px;
}

p{
  font-size: 14px;
  color: #fff;
}
.height{
  height: 100vh;
}
.box {
  text-align: center;
}
.for-pass {
  background: transparent;
  box-shadow: none;
  color: #9098a9;
}

.for-pass:hover {
  background: transparent !important;
  color: #C42532;
}
.shadow {
  -webkit-box-shadow: 0px 0px 15px -3px #ccc;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 0px 15px -3px #ccc;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 0px 15px -3px #ccc;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  padding: 2px;
}
.for-pass{
  padding: 0px !important;
}
.pad-btm{
  padding-bottom: 65px;
}
.btn-outline:focus{
  outline:none;
}
a:focus {
  text-decoration: none;
  color: none;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
.optPhoto-btn{
  padding: 3rem 0rem 3rem 0rem;
}
.optionalimg{
  margin: 0 auto;
}
.skip-btn{
  width: 100%;
  padding: 0;
  animation: 0.7s ease-in-out 0s 1 fadeInUp;
}
.skip-btn button{
  background: rgba(0, 0, 0, 0.6);
  color: #a0a0a0;
  border: 1.3px solid #a0a0a0;
  font-size: 12px;
}

.layer {
  width: 100%;
    margin: auto;
    height: 100%;
}

.ios-fonts{
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.android-fonts{
font-family: 'Roboto', sans-serif;
}

.windows-fonts{
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

.next_fixed_btn {
  position: relative;
  left: 0;
  bottom: 60px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .mo-scroll {
      overflow-x: scroll;
      height: 68vh;
  }
  .next_fixed_btn {
    bottom: 60px;
    top: 0px;
  }
  .enter-data-body .auto-approved {
    margin:14px auto 10px;
  }
  .enter-data-body {
    padding: 0px;
  }
  .select-vehicle .tab-wrapper {
    height: 100%;
  }
  .tab-wrapper .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}



.login-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-body .row{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.layer {
  margin-top: 0;
}

/* theme btn style */
.buttonCls:hover {
  background-color: #C42532;
}
.dashboard-body a{
  text-decoration: none !important;
}
.dashboard-body .dash-item {
  opacity: 1;
  -webkit-appearance: none;
}
/* end of theme btn style */

.portrait-mode h1 {
  font-size: 22px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.portrait-mode p {
  font-size: 12px;
  text-align: center;
  opacity: 1;
  margin: 0;
  color: #fff;
  font-weight: 300;
}
.portrait-mode img {
  max-width: 120px;
  margin-bottom: 15px;
}
.portrait-mode {
  width: 100vw;
  height: 100vh;
  background: #C42532;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carColor{
  margin: 1em auto;
  display: flex;
  justify-content: center;
  width: 50%;
  color: #000;
  flex-direction: column;
}

.carColor span{
  color: #ffffff;
  padding: 40px;
  font-size: 12px;
  font-weight: 600;
}
.carColor label{
  font-weight: 600;
}

#dashboard-body>div [class*='animate-'] {
  margin-bottom: 0 !important;
}

.vertically-middle{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.layer {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.bg {
  left: 0;
  position: fixed;
  top: 0;
  background-image: url('./Assets/abn-bg-new.png') !important;
  height: 100vh;
  width: 100%;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Media Query Landscape Mode */
@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .wrapper{
    background-image: url(./Assets/abn-bg-new-landscape.jpg);
    height: 100vh;
  }
  .skip-btn{
    padding: 0rem 4rem 0rem 0rem;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .wrapper {
    top: 66px;
    bottom: 59px;
  }

  .enter-data-body .auto-approved {
    margin: 12px auto 8px;
    font-size: 20px;
  }

  .fixed-top{
    top: 66px!important;
  }

}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
  .wrapper {
    top: 66px;
    bottom: 59px;
}
.fixed-top{
  top: 66px!important;
}
  
}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
  .wrapper {
    top: 66px;
    bottom: 59px;
}
.fixed-top{
  top: 66px!important;
}
 
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  .wrapper {
    bottom: 68px;
  }
  .fixed-top{
    top: 66px!important;
  }
  }

  @media screen and (min-width: 676px) {

    .wrapper {
      height: auto;
    }

  }





  