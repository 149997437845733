.buttonCls {
    background-color: #C42532;
    color: #fff;
    padding: 8px;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    outline: none !important;
    border-radius: 7px;
    background: -moz-linear-gradient(-45deg, rgba(154,9,21,1) 0%, rgba(255,15,71,0.88) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transform-style: preserve-3d;
    will-change: transform;
    border:none;

}

.home-body button, .login-body button, .next_fixed_btn button, .confirmSubmit button {
    border-top: #ffbe45 1px solid !important;
}

.home-body .for-pass, .login-body .for-pass {

    border: none !important;
}


.buttonCls:hover{
    background: -moz-linear-gradient(-45deg, rgba(255,15,71,0.88) 0%, rgba(154,9,21,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(255,15,71,0.88) 0%,rgba(154,9,21,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(255,15,71,0.88) 0%,rgba(154,9,21,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
}

.buttonCls:disabled {
    opacity: .5;
}

.btn:hover {
    color: #fff;
    cursor: pointer;
    background: #c43c47;
    transition: all 0.3s ease-in-out;

}
.btn:focus {
    box-shadow: none !important;
}

button[disabled]{
    background-color: #c43c47;
}
.button-wrapper{
    width: 72%;
    margin: 0 auto 10px;
}

.button-label{
    border-bottom: 0.8px solid;
}

.button-label:first-of-type{
    border-top: 0.8px solid;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .buttonCls {
        padding: 6px;
        font-size: 14px;
    }
}