.listview-body-wrapper {
  width: 100%;
  max-width: 700px;
}
.listview-body {
  margin-bottom: 40px;
  width: 100%;
  background-color: #efefef;
  border-radius: 7px;
}

.listview-minheight{
  height: 42px;
  padding: 7px;
  padding-bottom: 30px;
}

.listview-body.listview-minheight{
 margin-bottom: 15px; 
}

.listview-body .img img{
  border-radius: 50%;
  border: 3px solid #efefef;
  top: -19px;
  position: relative;
  left: 12px;
  width: 50px;
  height: 50px;
  background: #efefef;
}
.listview-body p.cus-title{
  font-size: 0.9rem;
  color: #3d3d3d;
  font-weight: 600
}

.users-body .listview-body p.cus-titleDate {
  font-size: 12px !important;
  font-style: italic !important;
}

.listview-body p.listview-time{
  font-size: 10px;
  color: #3d3d3d;
  font-weight: bold;
  padding: 5px 0px;
}

.listview-body .listview-body-approved{
  background: #55AE3A;
  color: #fff;
  font-weight: 600;
}

.listview-body .listview-body-new{
  background: #89cff0;
  font-weight: 600;
}
.listview-body .listview-body-submitted{
  background: #E86100;
  font-weight: 600;
}
.listview-body .listview-body-rejected{
  background: #C42532;
  font-weight: 600;
}

body{
  counter-reset: section;
}

.listview-body-rejected::before {
  counter-increment: section;
  content: "Ranked #" counter(section) ": ";
  color: yellow;
}

.listview-body .listview-body-approved,.listview-body .listview-body-submitted,.listview-body-new,.listview-body-rejected{
  padding: 4px;
  border-radius: 15px;
  top:10px;
  position: relative;
  font-size: 0.7rem;
}

.listview-body-wrapper .his-date p {
  color: yellow;
  font-size: 16px;
}

.users-body .listview-body-rejected {
  font-size: 12px !important;

}

.strong{
  font-weight: 700;
}
.listview-body .his-date{
  color: #efefef;
  font-size: 12px
}
.listview-body-wrapper .his-date p{
  box-shadow: 0 1px 9px #777;
  padding: 5px 0px;
}   

.listview-body .listview-arrow{
    float: right;
    text-align: center;
    margin: 0 AUTO;
    position: absolute;
    top: 0;
    left: 83%;
    right: 0;
    bottom: 0;
    color: #C42532;
    top: 16px;
}

.listview-body.listview-minheight .listview-arrow{
  top: 9px;
}

.listview-body .listview-arrow img{
  max-width: 14px;
}
/* Animation Effect for history section */
@keyframes bounceIn {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.listview-body{  
  animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -o-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
}
.listview-body .listview-sales{
  font-size: 10px;
    color: #C42532;
    margin-left: auto;
    padding-right: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.listview-sales label {
  margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    align-self: flex-start;
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .listview-body {
    padding-bottom: 50px;
  }
  .listview-body-wrapper .tab-hor{    
    float: left;
  }
}