#dashboard-body .modals-main Button {
  background-color: #C42532 !important;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  outline: none !important;
  border-radius: 7px;
  background: -moz-linear-gradient(-45deg, rgba(154, 9, 21, 1) 0%, rgba(255, 15, 71, 0.88) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(154, 9, 21, 1) 0%, rgba(255, 15, 71, 0.88) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(154, 9, 21, 1) 0%, rgba(255, 15, 71, 0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  will-change: transform;
  border: none !important;
  margin: 10px auto;
 text-align: center;

  border-top:#ffbe45 1px solid !important;
  width: 65% !important;

}

#dashboard-body .modals-main {
  text-align: center;
  background-color: #212529;
  color: #ffffff;
  border: none;

}

#dashboard-body .modals-main .button-label {

  border: none;

}

#dashboard-body .text-center {
  z-index: 9999;
}

#dashboard-body .dashboard-icon .dash-item .icon img {
  max-width: 100%;
  width: 100%;
}

#dashboard-body .menu-txt p{
  font-size: 12px;
  font-weight: bolder;
  text-shadow: 1px 1px darkred;
}
#dashboard-body .icon{
  text-align: center;
}

#dashboard-body
.dash-item {
  color: #fff;
  opacity: 0.8;
  z-index: 9999;
  padding: 2.5% 0%;

  border-top:#ffbe45 1px solid;
}

.animate-one .dash-item {
  border-radius: 0px 20px 5px 0px;

}

.animate-three .dash-item {
  border-radius: 0px 5px 20px 0px;
}

.animate-two .dash-item  {
  border-radius: 5px 0px 0px 20px;
}
.animate-one, .animate-three{
  transform: translateX(-100%)

}
.animate-two, .animate-four{
  transform: translateX(100%)
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-one{
  animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -o-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;

}
.animate-two {
  -webkit-animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
  -moz-animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
  -o-animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
  animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
}
.animate-three {
  animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -webkit-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -moz-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -o-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  opacity: 1;  
}
.animate-four {
  -webkit-animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  -moz-animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  -o-animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  opacity: 1;  
}

.labelDeliverType{
  margin: 20px;
  font-size: 20px;
  text-align: center;

}

.modalOptions{
  background-color: transparent;
  color: #000;
  box-shadow: 0 0 0 0 transparent;
  padding: 15px;
  background: transparent;

}

.modalOptions:hover {
  background: transparent;
  background-color: #ddefff;
    font-weight: 600;
}

/* 23-12-2019 */
#dashboard-body .menu-txt p {
  font-size: 14px;
}
/* end of 23-12-2019 */

#dashboard-body {
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

 #dashboard-body.flex-wrap{
    margin-top: 0;
    height: 100%;
    width: 100%;
    display: table;
} 

#dashboard-body.flex-wrap .container.p-0{
  display: table-cell;
  vertical-align: middle;
}

.custom-margin-bottom-15{
  margin-bottom: 15px;
  display: block;
}

.custom-b-margin{
  margin-bottom: 25px;
}



@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  #dashboard-body {
    position: relative;
  }
  #dashboard-body .icon{
    text-align: center;
  }
  #dashboard-body .menu-txt p{
    font-size: 18px;
  }

  #dashboard-body .dashboard-icon .dash-item .icon img{
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

    #dashboard-body
  .dash-item {
    padding: 6% 0%;
  }
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)	{
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }
  
  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

@media only screen and (max-width:380px) {
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

/*IPhone X */
@media only screen and (device-width : 375px) and (device-height : 812px){
  #dashboard-body{    
    padding: 2rem 0rem 5rem 0rem;
 }  
  .dash-item{
      padding: 10% 0%;
    }
    #dashboard-body .menu-txt p{
      font-size: 14px;
    }
    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100px;
      width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

/* Pixals 2XL*/
@media screen and (min-width: 411px) and (max-width: 767px) { 
  #dashboard-body{    
    padding: 2rem 0rem 4rem 0rem;
 }
  
  #dashboard-body .menu-txt p{
    font-size: 16px;
  }
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 7% 0%;
  }
}
/* Pixals 2XL*/
@media screen and (device-width: 411px) and (device-height: 823px) { 
  #dashboard-body{    
    padding: 2rem 0rem 6rem 0rem;
 }

 #dashboard-body .dash-item {
   padding: 6% 0%;
 }
}

/*IPhone 6/7/8 */
@media screen and (device-width : 375px) and (device-height : 676px){
  
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 4% 0%;
  }

} 

@media screen and (min-width: 320px) and (max-width: 359px){
  #dashboard-body{    
    padding: 2rem 0rem 2rem 0rem;
  }

  #dashboard-body .dash-item {
      padding: 4% 0%;
    }

    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100px; 
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    #dashboard-body .menu-txt p{
      font-size: 12px;
    }
    
    .custom-b-margin{
      margin-bottom: 15px;
    }

   .listview-body p.cus-title {
      font-size: 0.7rem;
   }

   .listview-body .img img {
    width: 40px;
    height: 40px;
    }

  }

  @media only screen and (max-device-width: 568px) and (min-device-width: 374px){
    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100%;
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    .custom-b-margin{
      margin-bottom: 18px;
    }
  
    #dashboard-body .dash-item {
      padding: 5% 0%;
    }

  }
  
  @media screen and (min-width: 676px) {

    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 30%;
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    #dashboard-body .dash-item {
      padding: 1% 0%;
    }

  }