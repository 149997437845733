.confirmSubmit {
    background-color: rgba(61, 61, 61, 0.5);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;

  }
.consent{
  font-style: italic;
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
  font-size: 14px;
  color: gray;
}
  .confirmSubmit .banner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 30px;

  }
  .confirmSubmit .banner img {
    -webkit-transform: translateY(10%);
    -ms-transform: translateY(10%);
    transform: translateY(10%);
    max-width: 100%;
    max-height: 180px;
}
  .confirmSubmit h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
    color: #efefef;
  }
  .confirmSubmit .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
    text-transform: capitalize;

  }
  .confirmSubmit .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    font-size: 20px !important;
  }
  .confirmSubmit .actions p{
    color: #ccc6c6;
    text-align: justify;
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .confirmSubmit-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

  .rotate-row{
    margin-top: 10px;
  }
  


/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .confirmSubmit-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .confirmSubmit-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .confirmSubmit-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .confirmSubmit-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }

  .confirmSubmit-body {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 0;
}

#imageBox img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}