footer{
    position: absolute;
    width: 100%;
    height: 68px;
    bottom: 0;
    z-index: 9999;

}
.footer-body{
    background: #000;
}
.footer-body img{
    max-width: 34px;
}
.footer-body p{
    text-transform: uppercase;
    font-size: 12px;
    color: #c1bebe;
}
.footer-body a {
    display: block;
    padding: 0.5rem 0;
}

/* -------------CSS Media-query---------------- */
  /* iPhone 5/SE in portrait & landscape */
  @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
   
    footer {
        height: 59px;
    }

    .footer-body img {
        max-width: 25px;
    }
  }

  /* iPhone 6, 7, & 8 in portrait & landscape */
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
    footer {
        height: 59px;
    }

    .footer-body img {
        max-width: 25px;
    }
  }
  
  /* iPhone 6, 7, & 8 Plus in portrait & landscape */
  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
    footer {
        height: 60px;
    }

    .footer-body img {
        max-width: 26px;
    }
  }

    /* iPhone X in portrait & landscape */
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) { 
    footer {
        height: 68px;
    }

    .footer-body img {
        max-width: 34px;
    }
}

@media only screen and (min-height: 740px) { 
    footer {
        height: 68px;
    }

    .footer-body img {
        max-width: 34px;
    }
 }