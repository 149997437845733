.videoBroadcast-body{
    padding: 2rem 0rem 5rem 0rem
}

.videoBroadcast-body img{
    max-width: 100%;
    max-height: 350px;
    border: 5px solid #333232;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
}