@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





#header{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 999;
  background: #000;

}

.network {
    background-color: #ffbe45;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    padding: 5px 0px;
}

.network img {

    float: left;
    margin-left: 20px;
    margin-top: -5px;
    margin-right: 10px;
}

.header-body{
    text-align: center;
}

.header-body p{
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 2px solid #a1bbc8;
    opacity: 0.8;
}
.backbtn{
  width: 34px;
  height: 34px;
  float: left;
  position: absolute;
  left: 4%;
  border-radius: 50%;
  }
  .helpbtn{
    border-radius: 0%;
  }
  .logoutbtn{
    float: right;
    right: 4%;
    left: auto;
    border-radius: 0%;
  }

  
/* ----------------------CSS Media-query------------------- */
 /* iPhone 5/SE in portrait & landscape */
 @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  #header {
    height: 66px;
  }
}

  /* iPhone 6, 7, & 8 in portrait & landscape */

  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
    #header {
      height: 66px;
    }
  }
  
  /* iPhone 6, 7, & 8 Plus in portrait & landscape */
  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
    #header {
      height: 66px;
    }
  }

  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) { 
    
  }
footer{
    position: absolute;
    width: 100%;
    height: 68px;
    bottom: 0;
    z-index: 9999;

}
.footer-body{
    background: #000;
}
.footer-body img{
    max-width: 34px;
}
.footer-body p{
    text-transform: uppercase;
    font-size: 12px;
    color: #c1bebe;
}
.footer-body a {
    display: block;
    padding: 0.5rem 0;
}

/* -------------CSS Media-query---------------- */
  /* iPhone 5/SE in portrait & landscape */
  @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
   
    footer {
        height: 59px;
    }

    .footer-body img {
        max-width: 25px;
    }
  }

  /* iPhone 6, 7, & 8 in portrait & landscape */
  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
    footer {
        height: 59px;
    }

    .footer-body img {
        max-width: 25px;
    }
  }
  
  /* iPhone 6, 7, & 8 Plus in portrait & landscape */
  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
    footer {
        height: 60px;
    }

    .footer-body img {
        max-width: 26px;
    }
  }

    /* iPhone X in portrait & landscape */
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) { 
    footer {
        height: 68px;
    }

    .footer-body img {
        max-width: 34px;
    }
}

@media only screen and (min-height: 740px) { 
    footer {
        height: 68px;
    }

    .footer-body img {
        max-width: 34px;
    }
 }
.home .for-pass:active {
  outline: none;
}

.home-eula {
    text-decoration: underline;
    color: #ffffff;
    display: block;
}

.home-area{
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
}
/* --------------------------------------------------- */
.home-body{
bottom: 0;
position: fixed;
top: 0;
left: 0;
right: 0;
}
.logo {
  box-shadow: 0 0 0 rgba(196, 37, 50, 0.4);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
  border-radius: 50%;
  max-width: 200px;
}
.pulse:hover {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(196, 37, 50, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(196, 37, 50, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(196, 37, 50, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(196, 37, 50, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(1196, 37, 50, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(196, 37, 50, 0);
  }
}
.buttonCls {
    background-color: #C42532;
    color: #fff;
    padding: 8px;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    outline: none !important;
    border-radius: 7px; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    will-change: transform;
    border:none;

}

.home-body button, .login-body button, .next_fixed_btn button, .confirmSubmit button {
    border-top: #ffbe45 1px solid !important;
}

.home-body .for-pass, .login-body .for-pass {

    border: none !important;
}


.buttonCls:hover{ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(255,15,71,0.88) 0%,rgba(154,9,21,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
}

.buttonCls:disabled {
    opacity: .5;
}

.btn:hover {
    color: #fff;
    cursor: pointer;
    background: #c43c47;
    transition: all 0.3s ease-in-out;

}
.btn:focus {
    box-shadow: none !important;
}

button[disabled]{
    background-color: #c43c47;
}
.button-wrapper{
    width: 72%;
    margin: 0 auto 10px;
}

.button-label{
    border-bottom: 0.8px solid;
}

.button-label:first-of-type{
    border-top: 0.8px solid;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .buttonCls {
        padding: 6px;
        font-size: 14px;
    }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transition: all 0.5s ease-out;
}
.modals .inp .label {
  color: #2a2a2b;
  opacity: 0.6
}
@-webkit-keyframes wobble {
  0% {-webkit-transform: scale(1);transform: scale(1);}
  50% {-webkit-transform: scale(0.9);transform: scale(0.9);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes wobble {
  0% {-webkit-transform: scale(1);transform: scale(1);}
  50% {-webkit-transform: scale(0.9);transform: scale(0.9);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}

.modals-main{
  animation: 0.5s ease-in-out 0s 1 wobble;
  -webkit-animation: 0.5s ease-in-out 0s 1 wobble;
  -moz-animation: 0.5s ease-in-out 0s 1 wobble;
  -o-animation: 0.5s ease-in-out 0s 1 wobble;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.modals-main {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top:35%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  border-radius: 7px;
}

.close-icon{
  position: absolute;
  top: -2%;
  right: 2%;
  width: auto;
  height: 100%;
  text-align: center;
  line-height: 45px;
  color: #C42532;
  cursor: pointer;
  max-height: 35px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modals-main.rgs_form>.col-12 {
  padding: 0 !important;
}
.modals-main.rgs_form .button-wrapper {
  margin-bottom: 0 !important;
}
.modals {
  display: flex;
  -ms-align-items: center;
  align-items: center;
}
.modals-main.rgs_form  {
  width: 95%;
  margin: auto;
  right: 0;
  left: 0;
  padding-top: 20px;


}

.dashboard-body .messageSection p, .dashboard-body .messageSection label {
  color: #ffffff !important;
}


.close-icon {
  right: 5px;
  top:0;
}
.svg-inline--fa {
  height: 1.5em;
}

.overlay-fixed{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay-fixed section{
  height: auto;
  width: 100%;
}
.inp {
    position: relative;
    margin: auto;
    width: 100%;
}

.inp .label {
    position: absolute;
    top: 20px;
    left: 0;
    font-size: 16px;
    color: #f5f5f5;
    font-weight: 500;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: all 0.2s ease;
}

.inp .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: #07f;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: all 0.15s ease;
}

.inp input  {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 18px 0 8px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C42532;
    background: none;
    border-radius: 0;
    color: #f5f5f5;
    transition: all 0.15s ease;
    background: transparent;
}

.inp textarea {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 0px 0 8px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #C42532;
  background: none;
  border-radius: 0;
  color: #f5f5f5;
  transition: all 0.15s ease;
  background: transparent;
}

.inp input:hover, .inp textarea:hover {
    background: rgba(34, 50, 84, 0.03);
}

.inp input:not(:placeholder-shown)+span {
    color: #C42532;
    -webkit-transform: translateY(-26px) scale(0.75);
            transform: translateY(-26px) scale(0.75);
}

.inp textarea:not(:placeholder-shown)+span {
  color: #C42532;
  -webkit-transform: translateY(-38px) scale(0.75);
          transform: translateY(-38px) scale(0.75);
}

.inp input:focus, .inp textarea:focus {
    background: none;
    outline: none;
}

.inp input:focus+span {
    color: #c8ccd4;
    -webkit-transform: translateY(-26px) scale(0.75);
            transform: translateY(-26px) scale(0.75);
}

.inp textarea:focus+span {
  color: #c8ccd4;
  -webkit-transform: translateY(-38px) scale(0.75);
          transform: translateY(-38px) scale(0.75);
}

.inp input:focus+span+.border {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.eyepass{
    position: absolute;
    z-index: 9;
    width: 20px;
    top: 15px;
    right: 0px;
}

@-webkit-keyframes slideInFromLeft {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

@keyframes slideInFromLeft {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @-webkit-keyframes slideInFromRight {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);     
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);     
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .inp input[type=email]{
    -webkit-animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
            animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
  }
  .inp .label {
    -webkit-animation: 0.5s ease-in-out 0s 1 slideInFromRight;
            animation: 0.5s ease-in-out 0s 1 slideInFromRight;
  }

  
  @media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .actions.single-use .inp input  {
    padding: 15px 0 8px;
    height: 38px;
    font-size: 14px;
  }

  .auto-approved.container p{
    font-size: 12px;
  }
}

@media only screen and (max-device-width: 568px) and (min-device-width: 374px){
  .actions.single-user .inp input  {
    padding: 18px 0 8px;
    height: 42px;
    font-size: 14px;
  }

  .auto-approved.container p{
    font-size: 14px;
  }
}
.select-vehicle .tab-wrapper{
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.select-vehicle .box-1,.select-vehicle .box-2,.select-vehicle .box-3,.select-vehicle .box-4 {
    position: relative;
}
.select-vehicle .car-image img{
    max-width: 150px;
    height: auto;
}
.ddList{
    padding: 2em 0em;
}
.tab-wrapper{
    height: 100% !important;
}
.btn-circle {
    width: 35px;
    height: 35px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    background: #C42532;
    color: #fff;
    border: 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
}
.select-vehicle .inp-pre-owned{
    width: 76%;
}
.select-vehicle .inp-pre-owned .label,.select-vehicle .inp-pre-owned input{
    font-size: 12px !important;
}
.select-vehicle .inp-pre-owned-col{
    text-align: center;
}
.select-vehicle .inp-pre-owned .effect-20{
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;
}
.select-vehicle .carCount{
    box-shadow: 0 0px 7px #777;
    padding: 5px 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    margin: 0 auto;
}

.select-vehicle.selectVehicle .tab-wrapper {
    height: auto !important;
    padding-bottom: 0;

}

.tab-wrapper {
    padding-top: 50px;
}

.select-vehicle .box select{
    margin: 0.8em 0em;
}

.select-vehicle {
    padding-top: 0;
    height:100%;
    overflow-y: auto;

}
.selVeh_wrapper {
    position: relative !important;
    padding-top: 0px;
}

.undefined{
    padding-top: 10px;
}

.select-vehicle .carCount{
    margin-top: 1.5rem;
}
.select-vehicle.selectVehicle .carCount{
    margin-top: 0;
}
.select-vehicle.selectVehicle .car-image {
    margin-bottom: 0 !important;
}
.ddList .auto-approved {
    padding: 15px 10px 0;
    width: 72%;
}
.ddList .single-user {
    overflow: inherit;
    -ms-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}
.ddList .auto-approved .checkbox.switcher span {
    margin-right: 0;
}

.selectedCarColor{
    background-color: rgb(215, 216, 206);
    display: flex;
    height: 100px;
    width: 71%;
    margin: 0 auto;
    margin-bottom: 18px;
    justify-content: center;
    align-items: center;
}

.selectedCarColor p{
    margin-bottom: 0;
    background: #fff;
    color: #000;
    padding: 0 4px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .select-vehicle .car-image img {
        max-width: 100px;
    }

    .select-vehicle .box select{
        margin: 0.3em 0em;
    }

    .undefined.row{
        height: auto !important;
    }

    .undefined.undefined{
        font-size: 12px;
    }

    .btn-circle {
        width: 25px;
        height: 25px;
        padding: 2px 0;
    }

}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {

    .select-vehicle {
        height:100%;

    }
    .select-vehicle .car-image img {
        max-width: 120px;
    }

    .select-vehicle .box select{
        margin: 0.7em 0em;
    }

    .btn-circle {
        width: 25px;
        height: 25px;
        padding: 2px 0;
    }
}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
    .select-vehicle .car-image img {
        max-width: 130px;
    }

    .select-vehicle .box select{
        margin: .8em 0em;
    }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .select-vehicle {
        height:100%;
    }

    .select-vehicle .car-image img {
        max-width: 150px;
    }

    .select-vehicle .box select{
        margin: .8em 0em;
    }
}

@media only screen and (min-height: 740px) {
    .select-vehicle .car-image img {
        max-width: 160px;
    }

    .select-vehicle .box select{
        margin: 1em 0em;
    }
}

@media only screen and (min-device-width: 360px) and (min-device-height: 740px) {
    .select-vehicle .box select {
        margin: 0.8em 0em;
    }

    .box select, .carImgColor {
        padding: 1em .8em;
    }
}
.box select {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: 72%;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    border-top:#ffbe45 1px solid;
}
.box select option{
    background: transparent;
    padding: 20px;
    box-shadow: 0px 0px 7px 1px rgb(196,37,50);
}
select[disabled] {  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */  /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 0px 7px 1px rgb(196,37,50);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    opacity: .2;
}
.box select option {
    padding: 30px;
}


@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.box select,.carImgColor{
    -webkit-animation: 0.7s ease-in-out 0s 1 fadeInUp;
            animation: 0.7s ease-in-out 0s 1 fadeInUp;
    padding: .8em .8em;
    margin: 1em 0em;
}

.carImgColor{
    padding: 10px;
    margin: .8em 0em;
    width: 72%;
    font-size: 16px;
    border-radius: 7px;
    color: #fff;
    border-top: #ffbe45 1px solid;
    background-color: #C42532;
    opacity: .2;
}
.box-4 .carImgColor{
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
}
.box-4 .carImgColor p{
    font-size: 16px;
    text-align: left;
}
.carImgColor-wrapper .slider{
    height: auto;
}

.slider-wrapper {
    height: 69vh;
    position:relative;
}

.slider-scroll {
    height: inherit;
    overflow: auto;
}

.slick-slider .slick-list{
    min-height: 424px !important;
}

.carImgColor-wrapper .track .slide{
    background-size: 150px !important;
}
.carImgColor-wrapper .slider a.previousButton{
    left: -11px;
}
.carImgColor-wrapper .slider a.nextButton {
    right: -11px;
}
.carImgColor .modals{
    animation:none;
    -webkit-animation: none;
    -moz-animation: none;
    background: rgba(0, 0, 0, 0.1);
}
.select-vehicle-modal .modals-main {
    top:6%;
}
.select-vehicle-modal .slider-img{
    max-width: 50%;
}
.carImgColor-wrapper .modals-main{
    position: fixed;
    z-index: 500;
    width: 95%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 0;
    top: 8%;
    box-sizing: border-box;
    transition: all 0.5s ease-out;
    border-radius: 7px;
    right: 0;
    text-align: center;
    margin: 0 auto;
    background-color: #C42532;

}

.carImgColor-wrapper .slider-img{
    max-width: 49%;
}
.slick-slide img {
    display: inherit !important;
}
.carImgColor-wrapper svg:not(:root).svg-inline--fa {
    overflow: visible;
    font-size: 23px;
}

.slide.animateIn, .slide.animateOut{
    transition: all 0.25s linear !important;
}

/* CSS for custom vartical slider */
.carImgColor-wrapper .modals-main{
    padding: 30px !important;
}

.carImgColor-wrapper .slider-img {
    margin: 2px auto !important;
}

/* ---------------CSS for custom vertical slider--------------- */
/* ---------------slick-theme.css---------------- */

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    padding: 0px;
    border-radius: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: rgb(196, 37, 50);
}

.slick-next
{
    top: -2%;
}

.slick-prev
{
    bottom: -7%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: rgb(196, 37, 50);
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 16px;
    line-height: 1.2;

    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 48%;
}
[dir='rtl'] .slick-prev
{
    right: 48%;
    left: auto;
}

.slick-prev:before
{
    content: '↓';
}

[dir='rtl'] .slick-prev:before
{
    content: '↓';
}

.slick-next
{
    right: 45%;
}

[dir='rtl'] .slick-next
{
    right: auto;
    left: 45%;
}

.slick-next:before
{
    content: '↑';
}
[dir='rtl'] .slick-next:before
{
    content: '↑';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


/* CSS media-query */

/* iPhone 5/SE in portrait & landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {

    .box select, .carImgColor {
        padding: .5em .8em;
        font-size: 14px;
    }
}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    .box select, .carImgColor {
        padding: .6em .8em;
        font-size: 14px;
    }

}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
    .box select, .carImgColor {
        padding: .8em .8em;
        font-size: 15px;
    }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .box select, .carImgColor {
        padding: .8em .8em;
    }
}


@media only screen and (min-height: 740px) {
    .box select, .carImgColor {
        padding: 1em .8em;
        font-size: 15px;
    }
}

.user-filter select {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    border-top:#ffbe45 1px solid;
    margin-bottom: 30px;
}

.account-list li {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    margin-bottom: 4px;
}

.account-list li:hover {
    background-color: #FFF;
    color: #C42532;
    cursor: pointer;
}

.account-list {
    margin-top: 90px;
}

.account-list ul {
    list-style: none;
    margin-top: 10px;
    padding-left: 0;
    height: calc(100vh - 200px);
    overflow: auto;
}

.account-list input::-webkit-input-placeholder {
    color: #7c7c7c
}

.account-list input:-ms-input-placeholder {
    color: #7c7c7c
}

.account-list input::-ms-input-placeholder {
    color: #7c7c7c
}

.account-list input::placeholder {
    color: #7c7c7c
}

.input-box {
    display: flex;
}

.input-box input {
    flex-grow: 1;
}

.user-filter label, .account-list label {
    color: white;
    margin-right: 12px;
}



#loader-dim{
  background: rgba(0,0,0,.5);
  position:fixed;
  top:0;
  right: 0;
  left:0;
  bottom: 0;
  z-index:999;
  overflow: hidden;
  }

  #loader-dim .loader{
    background: url(/static/media/loader-2.9a59bfba.svg) no-repeat;
    top: 50%;
    left: 46%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
}
  
.help {
    background-color: rgba(61, 61, 61, 0.5);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .help .banner {
    height: 5.55rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
  .help .banner img {
    -webkit-transform: translateY(40%);
            transform: translateY(40%);
  }
  .help h3.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
    color: #efefef;
  }
  .help .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .help .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
  }
  .help img.address-info{
    max-width: 20%;
    margin: 0 auto;
  }
  .help .actions p{
    color: #ccc6c6;
    text-align: justify;
  }
  .help .module{
    background:none;
    flex: 1 1 35px;
  }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .help-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .help-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .help-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .help-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .help-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }

.container.help-body .module.buttonCls{
  -ms-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.container.help-body {
  overflow: auto;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.container.help-body .module.buttonCls a {
  width: 23%;

}



.help .actions p {
  margin: 0;
}
.contact-title{
  color:#fff !important;
}

.single-contact{
  margin-bottom: 30px;
  
}

.single-contact ul{
  margin-bottom:20px !important;
}

.single-contact .about-title{
  color: #fff !important;
  margin-top: 10px !important;
}

.single-contact>div {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.module.buttonCls a{
  transition: all 0.3s ease-in-out;
}

.module.buttonCls a:focus{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

  .container.help-body .module.buttonCls a{
    margin: 15px 2%;
  }
  .container.help-body .module.buttonCls a:first-child {
    margin-right: 20px;
  }


.about .buttonCls {

    border-top: #ffbe45 1px solid !important;

  }

.img_header {

  text-align: center;
}

.img_header img{
  height: 75px;
  width: 75px;
  margin: 10px 0px;
  background-color: #f5f5f5;
  border-radius: 15px;

}

.not-valid{
    text-decoration: none;
}
.red {
    color: #C42532 !important;
}
.login-body{
    position: relative;
} 
.login-body .login-inner{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    height: 100%;
    vertical-align: middle;
    bottom: 0
}
.login-body .password-info{
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    box-shadow: 0 0px 6px #777;
    text-align: center;
}
.login-body .password-info .pass-info-up span{
    display: inline-block;
    font-size: 12px;
    color: #9098a9;
}  
.login-body .password-info .pass-info-up p{
    display: inline-block;
    font-size: 12px;
    color: #9098a9;
} 
.login-body .password-info .pass-info-up span{
    font-size: 12px;
    border-bottom: 1px solid #fff;
}
.login-body .not-email{
    color: #fff !important;
}

select.select-account {
    width: 100%;
}

/* Media Query Landscape Mode */
@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .login-body {
        position: relative;
    } 
  }

  @media screen and (max-device-height: 350px){

  }

.login-body .password-info{
    position: relative;
}

.listview-body::after{
  display: none;
}
.history-body .listview-body p.his-title{
  padding: 5px 0px 0px 0px;
}
.history-body .tab-hor{
  margin-top: 1em;
}

.wrapper-history {
  color: #fff;
  padding-bottom: 0;
  padding-top: 0;
}

.wrapper-history .profile {
  text-align: center;
  padding: 10px 10px 30px;
  background: #000;
}

.wrapper-history .profile .pic-wrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
}

.wrapper-history .profile .pic-wrapper img {
  max-width: 100%;
}

.wrapper-history .profile .pic-wrapper p {
  font-weight: 700;
  text-transform: capitalize;
}

.wrapper-history .profile p {
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px 0 15px;
}

.wrapper-history .pro-data {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #282c34;
  border-left: 0;
  border-right: 0;
  background: #000;
  height: auto;
}

.wrapper-history .pro-data span {
  display: block;
  color: #ddd;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
}

.wrapper-history .pro-data>div {
  padding: 5px;
  font-weight: 700;
  font-size: 1.3rem;
}

.wrapper-history .thumb ul li {
  list-style: none;
  margin: 10px;
  padding: 0;
  border: 1px solid #494949;
}

.wrapper-history .thumb ul li .img-wrapper {
  height: 105px;
  width: 105px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.wrapper-history .thumb ul {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.wrapper-history .thumb ul li .relative {
  position: relative;
  cursor: pointer;
}

.wrapper-history .thumb ul li img {
  max-width: 100%;
}

.wrapper-history .thumb {
  padding: 10px;

  overflow: scroll !important;

}

.wrapper-history .thumb ul li span.label {
  position: absolute;
  right: 0;
  display: block;
  padding: 2px 3px 2px 5px;
  top: 5px;
  border-radius: 10px 0 0 10px;
  font-size: 0.5rem;
  font-weight: 700;
  background: #000;
  text-transform: uppercase;
  color: #fff;
}

.wrapper-history .thumb ul li span.label.green-lbl {
  background: #23a101;
}
.wrapper-history .thumb ul li span.label.blue-lbl {
  background: #89cff0;
}
.wrapper-history .thumb ul li span.label.orange-lbl {
  background: #E86100;
}

.wrapper-history .thumb ul li span.label.red-lbl {
  background: #c01f23;
}

.wrapper-history .thumb ul li .txt-thumb {
  background: #c3c6c8d9;
  color: #494949;
  font-size: 0.7rem;
  padding: 2px 2px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper-history .thumb ul li .dt-thumb {
  position: absolute;
  bottom: 22px;
  background: #d2f1c9;
  padding: 2px 3px;
  font-size: 0.6rem;
  right: 0;
  color: #282c34;
  font-weight: 700;
}

.wrapper-history {
  padding-top: 0;
}
.wrapper-history .profile {
  padding: 20px 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.wrapper-history .thumb ul li {
  margin: 15px 0 0 7.5px;
}
.wrapper-history .thumb {
  padding-top: 0;
}
.wrapper-history .thumb ul {
  padding: 0 10px 0 0;
  justify-content: center;
}

.fixed-top{
  position: fixed;
  top: 80px !important;
  right: 0;
  left: 0;
  width: 100%;
  height: 140px;
}

.wrapper-history {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
}

.wrapper-history .thumb {
  padding-top: 140px;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 120px;
  z-index: 1;
}

.fadeBG{
  background: url(/static/media/fadeBG.54b371f4.png);
  background-repeat: no-repeat;
  z-index: -1;
  position: fixed;
  height: 132px;
  width: 100%;
  bottom: 40px;
  top: auto;
  opacity: 0.8;
}

@media only screen and (max-width:380px) {
  .wrapper-history .thumb ul li .img-wrapper {
    height: 95px;
    width: 95px;
  }

  .fadeBG{
    bottom: 40px;
  }
}

@media only screen and (max-width:320px) {
  .wrapper-history .thumb ul li .img-wrapper {
    height: 75px;
    width: 75px;
  }
  .wrapper-history .thumb ul li span.label {
    font-size: 0.5rem;
  }
  .wrapper-history .thumb ul li .txt-thumb {
    font-size: 0.5rem;
  }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  .post-body {
    height: auto;
  }

  .display-block {
    display: block;
    height: auto;
  }

  .fixed-top {
    top: 66px!important;
  }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  .post-body {
    height: auto;
  }

  .display-block {
    display: block;
    height: auto;
  }

  .fixed-top {
    top: 66px!important;
  }

  .fadeBG{
    bottom: 40px;
  }
}
.grid {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1.6em;
    margin: 6%;
}

.support-body .dashboard-body {
    position: absolute !important;
}




.grid-2 {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 1.6em;
    margin: 6%;
}
.grid-2 .module{
    background: none
}
.reset-pass{
  color: #3d3d3d;;
  padding: 1em;
  text-align: center;
  font-size: 14px;
  margin: 0px;
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  }
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  }

.module {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1 1 200px;
  border-radius: 6%;
  animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -o-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
}

.module img {
    max-width: 20%;
    margin: 6%;
}
.module p{
    color: #efefef;
}

.grid-2 img{
  border: 3px solid rgba(61, 61, 61, 0.7);
  border-radius: 36%;
}

.support-body.pad-btm {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.support-body .dashboard-body>a:nth-child(even) [class*='col-'] {
  margin-left: auto;
}
.support-body .dashboard-body>a{
  display: inline-block;
  width: 100%;
}
.support-body #dashboard-body {
  height: 100%;
  overflow-y: auto;
  display: inline-block;
  width: 100%;
  padding: 20px 0 0;
   margin-top: 25%;
}
.support-body #dashboard-body a:last-child .animate-two {
  margin-bottom: 0 !important;
}

.react-confirm-alert-overlay {

    background: #0e0e0e !important;

}

.react-confirm-alert-button-group button {
    background-color: #C42532 !important;
    color: #fff;
    padding: 8px !important;
    font-size: 16px !important;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2) !important;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    outline: none !important;
    border-radius: 7px; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    will-change: transform;
    border:none;
    width: 100px !important;


}

.react-confirm-alert-body h1 {
    font-size: 24px !important;
    font-weight: bold !important;
    text-align: center !important;
}

.react-confirm-alert-button-group {
text-align: center !important;
    display: block !important;

}

.react-confirm-alert-body {
    text-align:center !important;
}

/* Media Query Landscape Mode */
  @media screen and (min-width: 320px) and (orientation: landscape) {
    .grid {
        display: flex;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: minmax(150px, auto);
        grid-gap: 0.6em;
        margin: 2%;
    }
    .grid-2 {
        display: flex;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-auto-rows: minmax(200px, auto);
        grid-gap: 0em;
        margin: 2%;
        text-align: center;
        
  } 
}

@media only screen

and (max-device-height : 667px) {

    .support-body #dashboard-body {
        padding: 0px 0 0;
        margin-top: 0%;
    }

}



  .post {
    background-color: rgba(61, 61, 61, 0.5) !important;
    color: #ffffff !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .post .banner {

    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 0;

}

  .post button {
    border-top: #ffbe45 1px solid !important;

  }
.post .banner img {

  padding: 0.5em;

  border-radius: 4%;

  margin-bottom: -40px;
  margin-top: 15px;

}
   .post h2.name {
    text-align: center;
    padding: 3rem 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
     color: #ffffff !important;
  }
  .post .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 0.8rem;
    color: #ffffff !important;
  }
  .post .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    order: 99;
    color: #ffffff !important;
  }
  .post .actions .follow-info h2 {
    text-align: center;
    width: 100%;
    margin: 0;
    color: #ffffff !important;
    box-sizing: border-box;
  }
  .post .actions .follow-info p,.desc h6 {
    color:#3d3d3d;
    font-size: 12px;
    font-weight: normal;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    color: #ffffff !important;
  }
  .post .actions .follow-btn p{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    border-top: #ffbe45 1px solid;

  }
  
  .post .desc {
    text-align: justify;
    padding: 1rem;
    order: 100;

  }
  .post .desc ul{
    list-style: none;
    padding-left: 0px;
  }
  .post .desc ul li{
    padding:0.3em;
  }
  .post .desc p{
    background: #C42532;
    padding:0.3em;
    text-align: center;
  }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .post-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    overflow-y: auto;
    height: 100%;
    display: inline-block;
    overflow-y: auto;
  }
  .post-status {
    color: #C42532;
    text-align: center;
    position: relative;
  }
  
.post .post-status-approved p{
  color: #55AE3A;
  font-weight: 600;
  font-size: 18px;
}

.post .post-status-new p{
  font-weight: 600;
  color: #89cff0;
  font-size: 18px;
}
.post .post-status-submitted p{
  font-weight: 600;
  color: #E86100;
  font-size: 18px;
}
.post .post-status-rejected p{
  font-weight: 600;
  color: #C42532;
  font-size: 18px;
}
  .post-status p{
    padding: 1.9px;
  }
  
.post .modals-main{
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 85%;
  background-color: #212529;
  border: none;
}
.post .button-wrapper {
  margin: auto;

    
}

.post-body.container {
  height: auto;
  padding-top: 0;
  overflow-y: auto;
  padding-bottom: 0;
}

  .display-block{
    display: block;
    height: 100%;
    margin: 20px;
  }


.modals-main.rgs_form .button-wrapper .buttonCls {
  margin-bottom: 0.5rem;
}

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .post .banner img {
      max-width: 50%;
      min-width: 150px;
      max-height: 120px;
    }
    
    .post .title {
      padding: 0 2rem 0.5rem;
  }

    .post .actions {
      padding: 0 2rem 1rem;
  }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .post .banner img {
      max-width: 50%;
      min-width: 150px;
      max-height: 130px;
    }

    .post .title {
      padding: 0 2rem 0.5rem;
  }

    .post .desc{
      padding: 0.5rem;
    }

    .post .actions {
      padding: 0 2rem 1rem;
  }

  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .post-body{    
      padding: 0rem 1rem 1rem 1rem;
      overflow-y: auto;
      height: 100%;
   }
  }

  

  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    
      .display-block {
        display: block;
        height: auto;
    }
    }

    @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 

        .display-block {
          display: inline-block;
          height: auto;
          overflow-y: auto;
          vertical-align: middle;
      }
    }
.users-body{
    margin-top: 2em;
}

.listview-body-wrapper {
    margin: 0;
    padding-top: 100px;
}

.users-body .listview-body p.his-title{
    padding: 5px 0px 0px 0px;
    font-wieght: bold !important;
    font-size: 16px !important;
    text-transform: capitalize;
    color: #ffffff !important;
    text-shadow: 1px 1px #000000;
}

 .users-body img{
    background-color: transparent !important;
    border: none !important;
     box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.5);


}

 .users-body .listview-arrow img  {


     box-shadow: none !important;


 }
.users-body .img {

    border: none !important;

}

.users-body .listview-body  {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7d7e7d+0,5b5b5b+22,0e0e0e+83 */
    background: #7d7e7d; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #7d7e7d 0%,#5b5b5b 22%,#0e0e0e 83%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e',GradientType=0 ); /* IE6-9 */

}

.users-body  .listview-body-rejected {
    border-top: 1px solid #ffc107 !important;
    text-shadow: 1px 1px darkred;
    text-transform: capitalize;

}

.listview-body-wrapper.pad-btm {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
    height: 100%;
    overflow: scroll !important;
}
.listview-body-wrapper .tab-hor:last-child .listview-body {
    margin-bottom: 10px;
}


.listview-body-wrapper {
  width: 100%;
  max-width: 700px;
}
.listview-body {
  margin-bottom: 40px;
  width: 100%;
  background-color: #efefef;
  border-radius: 7px;
}

.listview-minheight{
  height: 42px;
  padding: 7px;
  padding-bottom: 30px;
}

.listview-body.listview-minheight{
 margin-bottom: 15px; 
}

.listview-body .img img{
  border-radius: 50%;
  border: 3px solid #efefef;
  top: -19px;
  position: relative;
  left: 12px;
  width: 50px;
  height: 50px;
  background: #efefef;
}
.listview-body p.cus-title{
  font-size: 0.9rem;
  color: #3d3d3d;
  font-weight: 600
}

.users-body .listview-body p.cus-titleDate {
  font-size: 12px !important;
  font-style: italic !important;
}

.listview-body p.listview-time{
  font-size: 10px;
  color: #3d3d3d;
  font-weight: bold;
  padding: 5px 0px;
}

.listview-body .listview-body-approved{
  background: #55AE3A;
  color: #fff;
  font-weight: 600;
}

.listview-body .listview-body-new{
  background: #89cff0;
  font-weight: 600;
}
.listview-body .listview-body-submitted{
  background: #E86100;
  font-weight: 600;
}
.listview-body .listview-body-rejected{
  background: #C42532;
  font-weight: 600;
}

body{
  counter-reset: section;
}

.listview-body-rejected::before {
  counter-increment: section;
  content: "Ranked #" counter(section) ": ";
  color: yellow;
}

.listview-body .listview-body-approved,.listview-body .listview-body-submitted,.listview-body-new,.listview-body-rejected{
  padding: 4px;
  border-radius: 15px;
  top:10px;
  position: relative;
  font-size: 0.7rem;
}

.listview-body-wrapper .his-date p {
  color: yellow;
  font-size: 16px;
}

.users-body .listview-body-rejected {
  font-size: 12px !important;

}

.strong{
  font-weight: 700;
}
.listview-body .his-date{
  color: #efefef;
  font-size: 12px
}
.listview-body-wrapper .his-date p{
  box-shadow: 0 1px 9px #777;
  padding: 5px 0px;
}   

.listview-body .listview-arrow{
    float: right;
    text-align: center;
    margin: 0 AUTO;
    position: absolute;
    top: 0;
    left: 83%;
    right: 0;
    bottom: 0;
    color: #C42532;
    top: 16px;
}

.listview-body.listview-minheight .listview-arrow{
  top: 9px;
}

.listview-body .listview-arrow img{
  max-width: 14px;
}
/* Animation Effect for history section */
@-webkit-keyframes bounceIn {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes bounceIn {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.listview-body{  
  animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -o-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
}
.listview-body .listview-sales{
  font-size: 10px;
    color: #C42532;
    margin-left: auto;
    padding-right: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.listview-sales label {
  margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    align-self: flex-start;
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .listview-body {
    padding-bottom: 50px;
  }
  .listview-body-wrapper .tab-hor{    
    float: left;
  }
}
.single-user {
  background-color: rgba(61, 61, 61, 0.5);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 2rem;
  box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
  border-radius: 2%;
}

.users-body .listview-body .img img {

  border-radius: 50% !important;
  padding: 3px;

}

.listview-body .users-body img {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0px 10px 0px 0px rgba(0,0,0,0.5);

}

.single-user .banner {
  height: 5.55rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 20px;
}
.single-user .banner img {
  height: 75px;

}
.single-user h3.name {
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
  font-size: 22px;
  color: #efefef;
  text-transform: capitalize;
}
.single-user .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
}
.single-user .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
}
.single-user .actions p{
  color: #ccc6c6;
  text-align: justify;
}
.single-user .module{
  background:none;
  flex: 1 1 35px;
}
.single-user .slow  .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
.single-user button.trans{
  background-color: transparent;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 1);
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.single-user-body{  
  animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
}



/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .single-user-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .single-user-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .single-user-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}
/*iPhone X */
@media screen and (device-width: 375px) and (device-height: 812px){
  .single-user-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}
.single-user .auto-approved h5{
  color: #efefef;
  float: left
}
.single-user .switcher{
  float: right !important;
}

.single-user div.checkbox.switcher label input, div.radio.switcher label input {
  display: none;
}
.single-user div.checkbox.switcher label input + span, div.radio.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 56px;
  height: 28px;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.single-user div.checkbox.switcher label input + span small, div.radio.switcher label input + span small {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 0;
}
.single-user div.checkbox.switcher label input:checked + span, div.radio.switcher label input:checked + span {
  background: #C42532;
  border-color: #C42532;
}
.single-user div.checkbox.switcher label input:checked + span small, div.radio.switcher label input:checked + span small {
  left: 50%;
}


.single-user .tab-btn-inner .button-wrapper{
  width: 100%;
}
.single-user .tab-btn-inner .button-wrapper .buttonCls{
  border-radius: 0px;
  background-color: transparent;
  background:transparent;
}
.single-user .tab-btn-inner .btn-active{
  background-color: #C42532 !important;
}
.single-user .tab-btn-inner .btn-border{
  border: 2px solid #C42532 !important;
}

.container.single-user-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  overflow-y: auto;
}

/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){

  .single-user div.checkbox.switcher label input + span, div.radio.switcher label input + span {
    width: 44px;
    height: 22px;
    margin-right: 0;
  }

}

@media only screen and (max-device-width: 568px) and (min-device-width: 374px){
  
}
#dashboard-body .modals-main Button {
  background-color: #C42532 !important;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  outline: none !important;
  border-radius: 7px; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(154, 9, 21, 1) 0%, rgba(255, 15, 71, 0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  will-change: transform;
  border: none !important;
  margin: 10px auto;
 text-align: center;

  border-top:#ffbe45 1px solid !important;
  width: 65% !important;

}

#dashboard-body .modals-main {
  text-align: center;
  background-color: #212529;
  color: #ffffff;
  border: none;

}

#dashboard-body .modals-main .button-label {

  border: none;

}

#dashboard-body .text-center {
  z-index: 9999;
}

#dashboard-body .dashboard-icon .dash-item .icon img {
  max-width: 100%;
  width: 100%;
}

#dashboard-body .menu-txt p{
  font-size: 12px;
  font-weight: bolder;
  text-shadow: 1px 1px darkred;
}
#dashboard-body .icon{
  text-align: center;
}

#dashboard-body
.dash-item {
  color: #fff;
  opacity: 0.8;
  z-index: 9999;
  padding: 2.5% 0%;

  border-top:#ffbe45 1px solid;
}

.animate-one .dash-item {
  border-radius: 0px 20px 5px 0px;

}

.animate-three .dash-item {
  border-radius: 0px 5px 20px 0px;
}

.animate-two .dash-item  {
  border-radius: 5px 0px 0px 20px;
}
.animate-one, .animate-three{
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%)

}
.animate-two, .animate-four{
  -webkit-transform: translateX(100%);
          transform: translateX(100%)
}


@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}


@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.animate-one{
  animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;
  -o-animation: slideInFromLeft 0.7s ease-in-out 0s 1 forwards;

}
.animate-two {
  -webkit-animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
  animation: slideInFromRight 0.7s ease-in-out 0.3s 1 forwards;
}
.animate-three {
  animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -webkit-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -moz-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  -o-animation: slideInFromLeft 0.7s ease-in-out 0.6s 1 forwards;
  opacity: 1;  
}
.animate-four {
  -webkit-animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  animation: slideInFromRight 0.7s ease-in-out 0.9s 1 forwards;
  opacity: 1;  
}

.labelDeliverType{
  margin: 20px;
  font-size: 20px;
  text-align: center;

}

.modalOptions{
  background-color: transparent;
  color: #000;
  box-shadow: 0 0 0 0 transparent;
  padding: 15px;
  background: transparent;

}

.modalOptions:hover {
  background: transparent;
  background-color: #ddefff;
    font-weight: 600;
}

/* 23-12-2019 */
#dashboard-body .menu-txt p {
  font-size: 14px;
}
/* end of 23-12-2019 */

#dashboard-body {
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

 #dashboard-body.flex-wrap{
    margin-top: 0;
    height: 100%;
    width: 100%;
    display: table;
} 

#dashboard-body.flex-wrap .container.p-0{
  display: table-cell;
  vertical-align: middle;
}

.custom-margin-bottom-15{
  margin-bottom: 15px;
  display: block;
}

.custom-b-margin{
  margin-bottom: 25px;
}



@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  #dashboard-body {
    position: relative;
  }
  #dashboard-body .icon{
    text-align: center;
  }
  #dashboard-body .menu-txt p{
    font-size: 18px;
  }

  #dashboard-body .dashboard-icon .dash-item .icon img{
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

    #dashboard-body
  .dash-item {
    padding: 6% 0%;
  }
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)	{
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }
  
  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

@media only screen and (max-width:380px) {
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 100%;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

/*IPhone X */
@media only screen and (device-width : 375px) and (device-height : 812px){
  #dashboard-body{    
    padding: 2rem 0rem 5rem 0rem;
 }  
  .dash-item{
      padding: 10% 0%;
    }
    #dashboard-body .menu-txt p{
      font-size: 14px;
    }
    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100px;
      width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 6% 0%;
  }
}

/* Pixals 2XL*/
@media screen and (min-width: 411px) and (max-width: 767px) { 
  #dashboard-body{    
    padding: 2rem 0rem 4rem 0rem;
 }
  
  #dashboard-body .menu-txt p{
    font-size: 16px;
  }
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 7% 0%;
  }
}
/* Pixals 2XL*/
@media screen and (device-width: 411px) and (device-height: 823px) { 
  #dashboard-body{    
    padding: 2rem 0rem 6rem 0rem;
 }

 #dashboard-body .dash-item {
   padding: 6% 0%;
 }
}

/*IPhone 6/7/8 */
@media screen and (device-width : 375px) and (device-height : 676px){
  
  #dashboard-body .dashboard-icon .dash-item .icon img {
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item .icon img{
    max-width: 30px;
    width: 100%;
  }

  #dashboard-body .dash-item {
    padding: 4% 0%;
  }

} 

@media screen and (min-width: 320px) and (max-width: 359px){
  #dashboard-body{    
    padding: 2rem 0rem 2rem 0rem;
  }

  #dashboard-body .dash-item {
      padding: 4% 0%;
    }

    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100px; 
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    #dashboard-body .menu-txt p{
      font-size: 12px;
    }
    
    .custom-b-margin{
      margin-bottom: 15px;
    }

   .listview-body p.cus-title {
      font-size: 0.7rem;
   }

   .listview-body .img img {
    width: 40px;
    height: 40px;
    }

  }

  @media only screen and (max-device-width: 568px) and (min-device-width: 374px){
    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 100%;
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    .custom-b-margin{
      margin-bottom: 18px;
    }
  
    #dashboard-body .dash-item {
      padding: 5% 0%;
    }

  }
  
  @media screen and (min-width: 676px) {

    #dashboard-body .dashboard-icon .dash-item .icon img {
      max-width: 30%;
      width: 100%;
    }

    #dashboard-body .dash-item .icon img{
      max-width: 30px;
      width: 100%;
    }

    #dashboard-body .dash-item {
      padding: 1% 0%;
    }

  }
#account_logo {
    max-height: 18%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 90%;
}




.myProfile-body .post {
  background-color: rgba(61, 61, 61, 0.5) !important;
  color: #ffffff !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .myProfile-body .inp .label {
    color: #a0a0a0;
    font-size: 0.8rem;
  }
  .myProfile-body .inp input {
    color: #ffffff;


  }
  .myProfile-body .inp .border{
    background: #a0a0a0;
  }
  .myProfile-body .post .profile-logo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 3rem;

  }
  .myProfile-body .post .profile-logo img {
    width: 7rem;
    height: 7rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    border-radius: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    border: 2px solid #efefef;
  }
  
  .myProfile-body .post h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
  }
  .myProfile-body .post .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .myProfile-body .post .actions .follow-btn button{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    border-top: #ffbe45 1px solid;
  }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .myProfile-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

.container.myProfile-body {
  padding-top: 0;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 0;

}

.myProfile-body .tab-wrapper {
  height: 100% !important;
  padding-bottom: 80px;
}
.myProfile-body .box select{
  margin: 0.92em 0em;
}
.myProfile-body {
  height: auto;
  padding-top: 80px;
}
.selVeh_wrapper {
  position: relative !important;
}

.myProfile-body .carCount{
  margin-top: 1.5rem;
}
.myProfile-body.selectVehicle .carCount{
  margin-top: 0;
}
.myProfile-body.selectVehicle .car-image {
  margin-bottom: 0 !important;
}
.ddList .auto-approved {
  padding: 15px 10px 0;
  width: 72%;
}
.ddList .single-user {
  overflow: inherit;
  -ms-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.ddList .auto-approved .checkbox.switcher span {
  margin-right: 0;
}
.actions.single-user {
  background: transparent;
  box-shadow: 0 0 0;
  overflow: visible;
  overflow: initial;
}

.auto-approved.container{
  margin-top: 5px;
}

.actions.single-user p {
  color: #ffffff !important;
  margin-bottom: 0;
  line-height: 25px;
}

.checkbox.switcher .label{
  margin-bottom: 0;
}

.restPasswordSection{
  text-align: center;
  width: 100%;
}

.restPasswordSection label{
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #353535;
  cursor: pointer;
  letter-spacing: .5px;
  font-weight: 600;
  color: #ffffff !important;
}

.restPasswordSection span{ 
  padding: 0 10px;
}


/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .myProfile-body{    
    padding: 2rem 1rem 6rem 1rem;
  }

  .myProfile-body .post h2.name {
    padding: 0 2rem 0rem;
    font-size: 14px;
  }

  .myProfile-body .post .profile-logo img {
    width: 4rem;
    height: 4rem;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  .myProfile-body .post .profile-logo {
    margin-bottom: .5rem;
  }

  .myProfile-body .post .actions .follow-btn  {
    margin-bottom: 5px;
  }

  .myProfile-body .post .actions .follow-btn button {
    padding: 0.3rem;
    font-size: 14px;
  }

}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .myProfile-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .myProfile-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}
/*iPhone X */
@media screen and (device-width: 375px) and (device-height: 812px){
  .myProfile-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}

.about {
  background-color: rgba(61, 61, 61, 0.5) !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  color: #ffffff;
  }

  .about .banner {
    height: 5.55rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 20px;

  }
  .about .banner img {
    height: 55px;
  }
  .about .about-title{
    margin-top: 1em;
  }
  .about img.address-info{
    max-width: 70%;
  }
  .about h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 14px;
  }
  .about .grid {
  display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: minmax(120px, auto);
    grid-gap: 1.6em;
    margin: 6%;
  }
  .about .title {

    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .about .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
  }
  .about .copyr{
    font-size: 12px;
    color: #ffffff !important;
  }
  .about .actions .follow-info h2 {
    text-align: center;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  .about .actions .follow-info p {
    color:#3d3d3d;
    font-size: 17px;
    font-weight: normal;
    font-weight: 600;
    text-align: center;
  }
  
  .about .desc {
    text-align: justify;
    padding: 1rem;
    order: 100;
    text-align: center;

  }
  .about .follow-phone{
    box-shadow: none;
  }
  .about .desc ul{
    list-style: none;
    padding-left: 0px;
  }
  .about .desc ul li{
    padding:0.3em;
  }
  .about .desc p{
    color: #3d3d3d;
    padding:0.3em;
    text-align: center;
  }

  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .about-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

.container.about-body{
  padding-top: 0;
  padding-bottom: 0;
  height:100%;
  overflow-y:auto;
}
.about-body .module {
  margin-bottom: 25px;
  height: auto;
}
.about .about-title {
  margin-top: 0;
}
.about .follow-phone {
  padding: 0;
  padding-bottom: 20px;
}

.about .actions {
  padding: 0 2rem .4rem;
}
  .about .desc p, .about .desc ul {
    margin: 0;
    padding: 0;
  }
  .about .desc ul li {
    padding: 0;
  }
.module.buttonCls {
  -webkit-appearance: none;
  padding-top: 5px;

}

.module.buttonCls a {
  display: inline-block;
  width: 20%;
  margin: 6%;
}
.module.buttonCls img {
  max-width: 100%;
  margin: 0;
}
.about .about-title {
  margin: 0 0 5px;
}

  .buttonCls img.telephone:hover{
  -webkit-filter:invert(100%);
          filter:invert(100%);
  }

  .buttonCls img.email:hover{
    -webkit-filter:invert(100%);
            filter:invert(100%);
}

.desktopView-email p,
  .desktopView-telephone p {
      display: none;
  }


/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .about-body{    
    padding: 2rem 1rem 6rem 1rem;
  }

  .about .banner {
    height: 4rem;
    margin-bottom: 10px;
  }

  .about .banner img {
    height: 45px;
  }

  .about .title {
    padding: 0 2rem 0rem;
  }

  .about .desc ul li {
  font-size: 14px;

  }

  .acc {
    font-weight: bold !important;
    color: #ffc107 !important;
    font-size: 20px !important;
  }

  .about-body .module {
  margin-bottom: 15px;
  }

  .about .desc {
    padding: .5rem;
  }

  .module.buttonCls a {
    width: 18%;
  }

  .about .actions {
    padding: 0 2rem .2rem;
  }

  .about .about-title {
    margin: 0 0 0px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .about-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .about-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}

/*iPhone X */

@media screen and (device-width: 375px) and (device-height: 812px){
  .about-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}

@media screen and (min-width: 676px) {

  .container.about-body {
    width: 60%;
  }

  .about .banner{
    height: 3rem;
    margin-bottom: 5px;
  }

  .about .banner img{
    height: 80%;
  }

  .about h2.name {
    font-size: .8rem;
    padding: 0;
  }

  .about .title {
    font-size: 0.80rem;
    padding: 0.1rem 2rem 0.1rem;
}

.about .desc ul li{
  font-size: .8rem;
}

.about-body .module {
  margin-bottom: 20px;
}

.about .about-title{
  margin: 0;
}

.about .desc {
  padding: 0;
}


.module.buttonCls .desktopView-email{
  width: 58%;

}

.module.buttonCls .desktopView-telephone{
  width: 30%;
}

.module.buttonCls a {
  text-align: center;
    margin: 1% 3%;
}

.module.buttonCls .desktopView-email img.email{
  width: 26%;
  margin-bottom: .1rem;
}

.module.buttonCls .desktopView-telephone img.telephone{
  width: 40%;
  margin-bottom: .5rem;
}

  .module.buttonCls a:hover {
    text-decoration: none;
  }
  
  .desktopView-email p,
  .desktopView-telephone p {
      font-size: 12px;
      display: block;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .desktopView-email p:hover,
  .desktopView-telephone p:hover {
      color: #000;
      transition: 0.3s ease-in-out all;
      text-decoration: none;
  }

}
@-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
              transform: translate3d(0, 20px, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
              transform: translate3d(0, 20px, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  .optPhoto-btn{
    padding: 3rem 0rem 3rem 0rem;
  }
  .optionalimg{
    margin: 0 auto;
  }
.oprtionalPhoto-wrapper .optionalPhoto{
    box-shadow:none;
    background-color: #C42532;
    border-top:#ffbe45 1px solid;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: 72%;
    font-size: 12px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    -webkit-animation: 0.7s ease-in-out 0s 1 fadeInUp;
            animation: 0.7s ease-in-out 0s 1 fadeInUp;
    padding: 1em 1em;
    margin: 1em 0em;
    margin: 0 auto;
  }
  .optionalPhoto{
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
  }
  .optionalPhoto p{
    font-size: 12px;
    text-align: center;
  }
  .oprtionalPhoto-wrapper label{
    padding: 10px;
    background: transparent;
    display: table;
    color: #fff;
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
    text-align: center;
  }
  .oprtionalPhoto-wrapper input[type="file"] {
      display: none;
  }
  .oprtionalPhoto-wrapper .showImg{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  .oprtionalPhoto-wrapper img {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    max-width: 70%;
    border: 5px solid #efefef;
    background: #efefef;
    margin-bottom: 1rem;
    max-height: 320px;
}

  .oprtionalPhoto-wrapper .choose-photo{
    padding: 0 0rem 1rem 0rem;
  }

  .oprtionalPhoto-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .messageSection{
    padding: 5px;
  }

  .messageSection .textCenter label {
    color: #000000;
    font-size: 20px;
  }

  .messageSection .textCenter label input {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .messageSection .textCenter {
    text-align: center;
  }

  .messageSection .textCenter .btn {
    color: #fff;
    margin-top: 1rem;
  }

  .messageSection .textCenter h5{
    color: #C42532;
    font-weight: bold;
    font-size: 22px;
  }

  .messageSection .textCenter p{
    color: #000000;
    font-size: 17px;
  }
.enter-data-body{
    padding: 0rem 0rem 8rem 0rem;
}
.enter-data-body .auto-approved{
    box-shadow: 0 0px 7px #777;
    padding: 5px 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    margin: 0 auto;
    color: #C42532;
}

.select-vehicle.enter-data-body {
    padding-top: 50px;
	height: 100%;
	overflow-y: auto;
}
.ddList {
    display: table;
	padding: 0;
	width: 100%;
	margin-bottom: 10px;
}
.ddList_box{
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}
.ddList_box .inp input:not(:placeholder-shown)+span {
    color: #C42532;
    -webkit-transform: translateY(-16px) scale(0.75);
            transform: translateY(-16px) scale(0.75);
}
.select-vehicle .ddList_box .box select {
    margin: 0.2em 0em;
}
.ddList_box .inp input:focus+span {
    color: #c8ccd4;
    -webkit-transform: translateY(-14px) scale(0.75) !important;
            transform: translateY(-14px) scale(0.75) !important;
}

/* -----------phone input----------- */
/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-marginTop: calc(var(--PhoneInputCountrySelectArrow-height) / 2);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-width: calc(var(--PhoneInputCountryFlag-height) * 3 / 2);
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
    align-items: center;
    margin: 0 auto;
    width: 66%;
    margin-bottom: 12px;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1 1;
	/* The phone number input should shrink
	   to make room for the extension input */
    min-width: 0;
    border: 0;
    padding: 8px;
    background-color: transparent;
    color: #fff;
    border-bottom: 2px solid #C42532;
    outline: none;
}

::-webkit-input-placeholder {
    color: #fff;
    font-size: 12px;
    opacity: 1; /* Firefox */
  }

:-ms-input-placeholder {
    color: #fff;
    font-size: 12px;
    opacity: 1; /* Firefox */
  }

::-ms-input-placeholder {
    color: #fff;
    font-size: 12px;
    opacity: 1; /* Firefox */
  }

::placeholder {
    color: #fff;
    font-size: 12px;
    opacity: 1; /* Firefox */
  }

.PhoneInputCountryIcon {
	width: calc(1em * 3 / 2);
	width: var(--PhoneInputCountryFlag-width);
	height: 1em;
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: 1em;
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: rgba(0,0,0,0.1);
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 1px rgba(0,0,0,0.5),
		inset 0 0 0 1px rgba(0,0,0,0.5);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	max-width: 100%;
	max-height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: 0.8;
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: 0.65;
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: 0.35em;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 0.3em;
	width: var(--PhoneInputCountrySelectArrow-width);
	height: 0.3em;
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-top: calc(var(--PhoneInputCountrySelectArrow-height) / 2);
	margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
	margin-left: 0.35em;
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: inherit;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: 1px;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: 1px;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	-webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
	        transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: 0.45;
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

/* A "divider" `<option/>` for a native `<select/>`. */
.PhoneInputCountrySelectDivider {
	font-size: 1px;
	background: black;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: #03b2cb;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 1px #03b2cb,
		inset 0 0 0 1px #03b2cb;
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: #03b2cb;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

/* -----------end here--------------- */

.box.col-12.box-6:last-child{
	margin-bottom: 10px;
}
.confirmSubmit {
    background-color: rgba(61, 61, 61, 0.5);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;

  }
.consent{
  font-style: italic;
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
  font-size: 14px;
  color: gray;
}
  .confirmSubmit .banner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 30px;

  }
  .confirmSubmit .banner img {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    max-width: 100%;
    max-height: 180px;
}
  .confirmSubmit h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
    color: #efefef;
  }
  .confirmSubmit .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
    text-transform: capitalize;

  }
  .confirmSubmit .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    font-size: 20px !important;
  }
  .confirmSubmit .actions p{
    color: #ccc6c6;
    text-align: justify;
  }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .confirmSubmit-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

  .rotate-row{
    margin-top: 10px;
  }
  


/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .confirmSubmit-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .confirmSubmit-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .confirmSubmit-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .confirmSubmit-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }

  .confirmSubmit-body {
    height: auto;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 0;
}

#imageBox img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
.cropper-container {
    margin-left: auto;
    margin-right: auto;
}

.btn-rotation {
    margin-bottom: 40px;
}

.adjustPhoto {
    width: 100%;
    padding-top: 12px;
}

.error-body .post {
    background-color: #F9FAFB;;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .error-body .sad404{
    max-width: 15%;
 }
  .error-body .post .profile-logo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 4rem;
  }
  .error-body .post .profile-logo img {
    width: 10rem;
    height: 10rem;
    -webkit-transform: translateY(35%);
            transform: translateY(35%);
  }
  
  .error-body .post h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 20px;
  }
  .error-body .post .actions .follow-btn button{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .error-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .error-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .error-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .error-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .error-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }
.listview-body::after{
    display: none;
}
.previous-post-body .listview-body p.his-title{
    padding: 5px 0px 0px 0px;
}
.previous-post-body .tab-hor{
    margin-top: 1em;
}

.listview-body-wrapper.history-body {
    height: 100%;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
   overflow: scroll !important;

}

.history-body{
    margin-top: 2em;
}

.listview-body-wrapper {
    margin: 0;
    padding-top: 100px;
}

.history-body .listview-body p.his-title, .history-body .listview-body p.listview-time{
    padding: 5px 0px 0px 10px !important;
    font-wieght: bold !important;
    font-size: 16px !important;
    text-transform: capitalize;
    color: #ffffff !important;
    text-shadow: 1px 1px #000000;
}

.history-body img{
    background-color: transparent !important;
    border: none !important;
    box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.4);

    width: 75px !important;
    height: 75px !important;

}

img.pttv_bg {

    width: 100% !important;
    height: 100% !important;
}

.history-body .listview-arrow img  {

    position: absolute !important;
    box-shadow: none !important;


}
.history-body.img {

    border: none !important;

}

.history-body .listview-body  {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7d7e7d+0,5b5b5b+22,0e0e0e+83 */
    background: #7d7e7d; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #7d7e7d 0%,#5b5b5b 22%,#0e0e0e 83%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e',GradientType=0 ); /* IE6-9 */

}

.history-body .listview-body-approved {
    border-top: 1px solid #ffc107 !important;
    text-shadow: 1px 1px darkred;
    text-transform: capitalize;
    background-color: #C42532 !important;

}

.listview-body-wrapper.pad-btm {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
}
.listview-body-wrapper .tab-hor:last-child .listview-body {
    margin-bottom: 10px;
}



.not-valid{
    text-decoration: none;
}
.red {
    color: #C42532 !important;
}
.userStatus-body{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
} 
.userStatus-body p.success-msg{
    color: rgb(255, 255, 255);
    background-color: rgba(61, 61, 61, 0.5);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
    padding: 1.5rem;
    text-align: center;
    color: #a0a0a0;
}
.userStatus-body p small{ padding: 1rem;}

/* Media Query Landscape Mode */
@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .userStatus-body{
        position: absolute;
        left: 50%;
        top: 67%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        margin-bottom: 4rem;
    } 
    .userStatus-body .password-info{
        position: relative;
    }
  }
.videoBroadcast-body{
    padding: 2rem 0rem 5rem 0rem
}

.videoBroadcast-body img{
    max-width: 100%;
    max-height: 350px;
    border: 5px solid #333232;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
}
.toast{
    transition: all 0.5s ease-in-out;
    z-index: 9999;
}
.toast .toast-logo{
    max-width: 22px;
}
.listview-body::after{
    display: none;
}

.history-body .listview-body p.his-title{
    padding: 5px 0px 0px 0px;
}
.history-body .tab-hor{
    margin-top: 1em;
}

.playPPTV {
    background-color: #4CAF50;
}

.playPPTV_off {
    background-color: #4CAF50;
    opacity: .5;
}

#pptvDetailContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center-data{
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-data .row{
    width: 100%;
}

.center-data .row .tab-hor a .listview-body{
    margin-bottom: 0px;
}

.pttv-container{
    width:100%;
}

.pttv-container p{
    text-align: center;
}

img.pttv_bg {


    opacity: .3;


}



@font-face {
  font-family: 'Karla-Regular';
  src: local('Karla-Regular'), url(/static/media/Karla-Regular.69b92f7e.ttf) format('truetype');
}
/* has to be scroll, not auto */
.root{
  -webkit-overflow-scrolling: touch;
}

div#root{
  height: 100vh;
}

html, body{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

code {
  font-family: 'Karla', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wrapper{
  overflow-x:hidden;
  background-image: url(/static/media/abn-bg-new.4ac4c6df.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  overflow: hidden;
  
}

.wrapper{
  background-image: none;
  display: block;
  position: absolute;
  right: 0;
  left:0;
  top:80px;
  bottom: 68px;
}

p{
  font-size: 14px;
  color: #fff;
}
.height{
  height: 100vh;
}
.box {
  text-align: center;
}
.for-pass {
  background: transparent;
  box-shadow: none;
  color: #9098a9;
}

.for-pass:hover {
  background: transparent !important;
  color: #C42532;
}
.shadow {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 0px 15px -3px #ccc;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  padding: 2px;
}
.for-pass{
  padding: 0px !important;
}
.pad-btm{
  padding-bottom: 65px;
}
.btn-outline:focus{
  outline:none;
}
a:focus {
  text-decoration: none;
  color: none;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.optPhoto-btn{
  padding: 3rem 0rem 3rem 0rem;
}
.optionalimg{
  margin: 0 auto;
}
.skip-btn{
  width: 100%;
  padding: 0;
  -webkit-animation: 0.7s ease-in-out 0s 1 fadeInUp;
          animation: 0.7s ease-in-out 0s 1 fadeInUp;
}
.skip-btn button{
  background: rgba(0, 0, 0, 0.6);
  color: #a0a0a0;
  border: 1.3px solid #a0a0a0;
  font-size: 12px;
}

.layer {
  width: 100%;
    margin: auto;
    height: 100%;
}

.ios-fonts{
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.android-fonts{
font-family: 'Roboto', sans-serif;
}

.windows-fonts{
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

.next_fixed_btn {
  position: relative;
  left: 0;
  bottom: 60px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .mo-scroll {
      overflow-x: scroll;
      height: 68vh;
  }
  .next_fixed_btn {
    bottom: 60px;
    top: 0px;
  }
  .enter-data-body .auto-approved {
    margin:14px auto 10px;
  }
  .enter-data-body {
    padding: 0px;
  }
  .select-vehicle .tab-wrapper {
    height: 100%;
  }
  .tab-wrapper .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}



.login-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-body .row{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.layer {
  margin-top: 0;
}

/* theme btn style */
.buttonCls:hover {
  background-color: #C42532;
}
.dashboard-body a{
  text-decoration: none !important;
}
.dashboard-body .dash-item {
  opacity: 1;
  -webkit-appearance: none;
}
/* end of theme btn style */

.portrait-mode h1 {
  font-size: 22px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.portrait-mode p {
  font-size: 12px;
  text-align: center;
  opacity: 1;
  margin: 0;
  color: #fff;
  font-weight: 300;
}
.portrait-mode img {
  max-width: 120px;
  margin-bottom: 15px;
}
.portrait-mode {
  width: 100vw;
  height: 100vh;
  background: #C42532;
  display: flex;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carColor{
  margin: 1em auto;
  display: flex;
  justify-content: center;
  width: 50%;
  color: #000;
  flex-direction: column;
}

.carColor span{
  color: #ffffff;
  padding: 40px;
  font-size: 12px;
  font-weight: 600;
}
.carColor label{
  font-weight: 600;
}

#dashboard-body>div [class*='animate-'] {
  margin-bottom: 0 !important;
}

.vertically-middle{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); 
}

.layer {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.bg {
  left: 0;
  position: fixed;
  top: 0;
  background-image: url(/static/media/abn-bg-new.3c6b604f.png) !important;
  height: 100vh;
  width: 100%;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Media Query Landscape Mode */
@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .wrapper{
    background-image: url(/static/media/abn-bg-new-landscape.0144880b.jpg);
    height: 100vh;
  }
  .skip-btn{
    padding: 0rem 4rem 0rem 0rem;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .wrapper {
    top: 66px;
    bottom: 59px;
  }

  .enter-data-body .auto-approved {
    margin: 12px auto 8px;
    font-size: 20px;
  }

  .fixed-top{
    top: 66px!important;
  }

}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
  .wrapper {
    top: 66px;
    bottom: 59px;
}
.fixed-top{
  top: 66px!important;
}
  
}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
  .wrapper {
    top: 66px;
    bottom: 59px;
}
.fixed-top{
  top: 66px!important;
}
 
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  .wrapper {
    bottom: 68px;
  }
  .fixed-top{
    top: 66px!important;
  }
  }

  @media screen and (min-width: 676px) {

    .wrapper {
      height: auto;
    }

  }





  
