#account_logo {
    max-height: 18%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 90%;
}



