.myProfile-body .post {
  background-color: rgba(61, 61, 61, 0.5) !important;
  color: #ffffff !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .myProfile-body .inp .label {
    color: #a0a0a0;
    font-size: 0.8rem;
  }
  .myProfile-body .inp input {
    color: #ffffff;


  }
  .myProfile-body .inp .border{
    background: #a0a0a0;
  }
  .myProfile-body .post .profile-logo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 3rem;

  }
  .myProfile-body .post .profile-logo img {
    width: 7rem;
    height: 7rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    border-radius: 50%;
    transform: translateY(50%);
    border: 2px solid #efefef;
  }
  
  .myProfile-body .post h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
  }
  .myProfile-body .post .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .myProfile-body .post .actions .follow-btn button{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    border-top: #ffbe45 1px solid;
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .myProfile-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

.container.myProfile-body {
  padding-top: 0;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 0;

}

.myProfile-body .tab-wrapper {
  height: 100% !important;
  padding-bottom: 80px;
}
.myProfile-body .box select{
  margin: 0.92em 0em;
}
.myProfile-body {
  height: auto;
  padding-top: 80px;
}
.selVeh_wrapper {
  position: relative !important;
}

.myProfile-body .carCount{
  margin-top: 1.5rem;
}
.myProfile-body.selectVehicle .carCount{
  margin-top: 0;
}
.myProfile-body.selectVehicle .car-image {
  margin-bottom: 0 !important;
}
.ddList .auto-approved {
  padding: 15px 10px 0;
  width: 72%;
}
.ddList .single-user {
  overflow: inherit;
  -webkit-box-shadow: 0 0 0;
  -ms-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.ddList .auto-approved .checkbox.switcher span {
  margin-right: 0;
}
.actions.single-user {
  background: transparent;
  box-shadow: 0 0 0;
  overflow: initial;
}

.auto-approved.container{
  margin-top: 5px;
}

.actions.single-user p {
  color: #ffffff !important;
  margin-bottom: 0;
  line-height: 25px;
}

.checkbox.switcher .label{
  margin-bottom: 0;
}

.restPasswordSection{
  text-align: center;
  width: 100%;
}

.restPasswordSection label{
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #353535;
  cursor: pointer;
  letter-spacing: .5px;
  font-weight: 600;
  color: #ffffff !important;
}

.restPasswordSection span{ 
  padding: 0 10px;
}


/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .myProfile-body{    
    padding: 2rem 1rem 6rem 1rem;
  }

  .myProfile-body .post h2.name {
    padding: 0 2rem 0rem;
    font-size: 14px;
  }

  .myProfile-body .post .profile-logo img {
    width: 4rem;
    height: 4rem;
    transform: translateY(20%);
  }

  .myProfile-body .post .profile-logo {
    margin-bottom: .5rem;
  }

  .myProfile-body .post .actions .follow-btn  {
    margin-bottom: 5px;
  }

  .myProfile-body .post .actions .follow-btn button {
    padding: 0.3rem;
    font-size: 14px;
  }

}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .myProfile-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .myProfile-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}
/*iPhone X */
@media screen and (device-width: 375px) and (device-height: 812px){
  .myProfile-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}