.listview-body::after{
    display: none;
}

.history-body .listview-body p.his-title{
    padding: 5px 0px 0px 0px;
}
.history-body .tab-hor{
    margin-top: 1em;
}

.playPPTV {
    background-color: #4CAF50;
}

.playPPTV_off {
    background-color: #4CAF50;
    opacity: .5;
}

#pptvDetailContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center-data{
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-data .row{
    width: 100%;
}

.center-data .row .tab-hor a .listview-body{
    margin-bottom: 0px;
}

.pttv-container{
    width:100%;
}

.pttv-container p{
    text-align: center;
}

img.pttv_bg {


    opacity: .3;


}


