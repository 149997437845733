  .post {
    background-color: rgba(61, 61, 61, 0.5) !important;
    color: #ffffff !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .post .banner {

    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 0;

}

  .post button {
    border-top: #ffbe45 1px solid !important;

  }
.post .banner img {

  padding: 0.5em;

  border-radius: 4%;

  margin-bottom: -40px;
  margin-top: 15px;

}
   .post h2.name {
    text-align: center;
    padding: 3rem 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
     color: #ffffff !important;
  }
  .post .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 0.8rem;
    color: #ffffff !important;
  }
  .post .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    order: 99;
    color: #ffffff !important;
  }
  .post .actions .follow-info h2 {
    text-align: center;
    width: 100%;
    margin: 0;
    color: #ffffff !important;
    box-sizing: border-box;
  }
  .post .actions .follow-info p,.desc h6 {
    color:#3d3d3d;
    font-size: 12px;
    font-weight: normal;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    color: #ffffff !important;
  }
  .post .actions .follow-btn p{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    border-top: #ffbe45 1px solid;

  }
  
  .post .desc {
    text-align: justify;
    padding: 1rem;
    order: 100;

  }
  .post .desc ul{
    list-style: none;
    padding-left: 0px;
  }
  .post .desc ul li{
    padding:0.3em;
  }
  .post .desc p{
    background: #C42532;
    padding:0.3em;
    text-align: center;
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .post-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    overflow-y: auto;
    height: 100%;
    display: inline-block;
    overflow-y: auto;
  }
  .post-status {
    color: #C42532;
    text-align: center;
    position: relative;
  }
  
.post .post-status-approved p{
  color: #55AE3A;
  font-weight: 600;
  font-size: 18px;
}

.post .post-status-new p{
  font-weight: 600;
  color: #89cff0;
  font-size: 18px;
}
.post .post-status-submitted p{
  font-weight: 600;
  color: #E86100;
  font-size: 18px;
}
.post .post-status-rejected p{
  font-weight: 600;
  color: #C42532;
  font-size: 18px;
}
  .post-status p{
    padding: 1.9px;
  }
  
.post .modals-main{
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 85%;
  background-color: #212529;
  border: none;
}
.post .button-wrapper {
  margin: auto;

    
}

.post-body.container {
  height: auto;
  padding-top: 0;
  overflow-y: auto;
  padding-bottom: 0;
}

  .display-block{
    display: block;
    height: 100%;
    margin: 20px;
  }


.modals-main.rgs_form .button-wrapper .buttonCls {
  margin-bottom: 0.5rem;
}

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .post .banner img {
      max-width: 50%;
      min-width: 150px;
      max-height: 120px;
    }
    
    .post .title {
      padding: 0 2rem 0.5rem;
  }

    .post .actions {
      padding: 0 2rem 1rem;
  }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .post .banner img {
      max-width: 50%;
      min-width: 150px;
      max-height: 130px;
    }

    .post .title {
      padding: 0 2rem 0.5rem;
  }

    .post .desc{
      padding: 0.5rem;
    }

    .post .actions {
      padding: 0 2rem 1rem;
  }

  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .post-body{    
      padding: 0rem 1rem 1rem 1rem;
      overflow-y: auto;
      height: 100%;
   }
  }

  

  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    
      .display-block {
        display: block;
        height: auto;
    }
    }

    @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 

        .display-block {
          display: inline-block;
          height: auto;
          overflow-y: auto;
          vertical-align: middle;
      }
    }