.help {
    background-color: rgba(61, 61, 61, 0.5);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .help .banner {
    height: 5.55rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
  .help .banner img {
    transform: translateY(40%);
  }
  .help h3.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 16px;
    color: #efefef;
  }
  .help .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .help .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
  }
  .help img.address-info{
    max-width: 20%;
    margin: 0 auto;
  }
  .help .actions p{
    color: #ccc6c6;
    text-align: justify;
  }
  .help .module{
    background:none;
    flex: 1 1 35px;
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .help-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .help-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .help-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .help-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .help-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }

.container.help-body .module.buttonCls{
  -webkit-box-shadow: 0 0 0;
  -ms-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.container.help-body {
  overflow: auto;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.container.help-body .module.buttonCls a {
  width: 23%;

}



.help .actions p {
  margin: 0;
}
.contact-title{
  color:#fff !important;
}

.single-contact{
  margin-bottom: 30px;
  
}

.single-contact ul{
  margin-bottom:20px !important;
}

.single-contact .about-title{
  color: #fff !important;
  margin-top: 10px !important;
}

.single-contact>div {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.module.buttonCls a{
  transition: all 0.3s ease-in-out;
}

.module.buttonCls a:focus{
  transform: scale(1.2);
}

  .container.help-body .module.buttonCls a{
    margin: 15px 2%;
  }
  .container.help-body .module.buttonCls a:first-child {
    margin-right: 20px;
  }


.about .buttonCls {

    border-top: #ffbe45 1px solid !important;

  }

.img_header {

  text-align: center;
}

.img_header img{
  height: 75px;
  width: 75px;
  margin: 10px 0px;
  background-color: #f5f5f5;
  border-radius: 15px;

}
