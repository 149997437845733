.inp {
    position: relative;
    margin: auto;
    width: 100%;
}

.inp .label {
    position: absolute;
    top: 20px;
    left: 0;
    font-size: 16px;
    color: #f5f5f5;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
}

.inp .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: #07f;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
}

.inp input  {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 18px 0 8px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C42532;
    background: none;
    border-radius: 0;
    color: #f5f5f5;
    transition: all 0.15s ease;
    background: transparent;
}

.inp textarea {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 0px 0 8px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #C42532;
  background: none;
  border-radius: 0;
  color: #f5f5f5;
  transition: all 0.15s ease;
  background: transparent;
}

.inp input:hover, .inp textarea:hover {
    background: rgba(34, 50, 84, 0.03);
}

.inp input:not(:placeholder-shown)+span {
    color: #C42532;
    transform: translateY(-26px) scale(0.75);
}

.inp textarea:not(:placeholder-shown)+span {
  color: #C42532;
  transform: translateY(-38px) scale(0.75);
}

.inp input:focus, .inp textarea:focus {
    background: none;
    outline: none;
}

.inp input:focus+span {
    color: #c8ccd4;
    transform: translateY(-26px) scale(0.75);
}

.inp textarea:focus+span {
  color: #c8ccd4;
  transform: translateY(-38px) scale(0.75);
}

.inp input:focus+span+.border {
    transform: scaleX(1);
}

.eyepass{
    position: absolute;
    z-index: 9;
    width: 20px;
    top: 15px;
    right: 0px;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);     
    }
    100% {
      transform: translateX(0);
    }
  }

  .inp input[type=email]{
    animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
  }
  .inp .label {
    animation: 0.5s ease-in-out 0s 1 slideInFromRight;
  }

  
  @media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .actions.single-use .inp input  {
    padding: 15px 0 8px;
    height: 38px;
    font-size: 14px;
  }

  .auto-approved.container p{
    font-size: 12px;
  }
}

@media only screen and (max-device-width: 568px) and (min-device-width: 374px){
  .actions.single-user .inp input  {
    padding: 18px 0 8px;
    height: 42px;
    font-size: 14px;
  }

  .auto-approved.container p{
    font-size: 14px;
  }
}