.select-vehicle .tab-wrapper{
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.select-vehicle .box-1,.select-vehicle .box-2,.select-vehicle .box-3,.select-vehicle .box-4 {
    position: relative;
}
.select-vehicle .car-image img{
    max-width: 150px;
    height: auto;
}
.ddList{
    padding: 2em 0em;
}
.tab-wrapper{
    height: 100% !important;
}
.btn-circle {
    width: 35px;
    height: 35px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    background: #C42532;
    color: #fff;
    border: 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
}
.select-vehicle .inp-pre-owned{
    width: 76%;
}
.select-vehicle .inp-pre-owned .label,.select-vehicle .inp-pre-owned input{
    font-size: 12px !important;
}
.select-vehicle .inp-pre-owned-col{
    text-align: center;
}
.select-vehicle .inp-pre-owned .effect-20{
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;
}
.select-vehicle .carCount{
    box-shadow: 0 0px 7px #777;
    padding: 5px 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    margin: 0 auto;
}

.select-vehicle.selectVehicle .tab-wrapper {
    height: auto !important;
    padding-bottom: 0;

}

.tab-wrapper {
    padding-top: 50px;
}

.select-vehicle .box select{
    margin: 0.8em 0em;
}

.select-vehicle {
    padding-top: 0;
    height:100%;
    overflow-y: auto;

}
.selVeh_wrapper {
    position: relative !important;
    padding-top: 0px;
}

.undefined{
    padding-top: 10px;
}

.select-vehicle .carCount{
    margin-top: 1.5rem;
}
.select-vehicle.selectVehicle .carCount{
    margin-top: 0;
}
.select-vehicle.selectVehicle .car-image {
    margin-bottom: 0 !important;
}
.ddList .auto-approved {
    padding: 15px 10px 0;
    width: 72%;
}
.ddList .single-user {
    overflow: inherit;
    -webkit-box-shadow: 0 0 0;
    -ms-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}
.ddList .auto-approved .checkbox.switcher span {
    margin-right: 0;
}

.selectedCarColor{
    background-color: rgb(215, 216, 206);
    display: flex;
    height: 100px;
    width: 71%;
    margin: 0 auto;
    margin-bottom: 18px;
    justify-content: center;
    align-items: center;
}

.selectedCarColor p{
    margin-bottom: 0;
    background: #fff;
    color: #000;
    padding: 0 4px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .select-vehicle .car-image img {
        max-width: 100px;
    }

    .select-vehicle .box select{
        margin: 0.3em 0em;
    }

    .undefined.row{
        height: auto !important;
    }

    .undefined.undefined{
        font-size: 12px;
    }

    .btn-circle {
        width: 25px;
        height: 25px;
        padding: 2px 0;
    }

}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {

    .select-vehicle {
        height:100%;

    }
    .select-vehicle .car-image img {
        max-width: 120px;
    }

    .select-vehicle .box select{
        margin: 0.7em 0em;
    }

    .btn-circle {
        width: 25px;
        height: 25px;
        padding: 2px 0;
    }
}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
    .select-vehicle .car-image img {
        max-width: 130px;
    }

    .select-vehicle .box select{
        margin: .8em 0em;
    }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .select-vehicle {
        height:100%;
    }

    .select-vehicle .car-image img {
        max-width: 150px;
    }

    .select-vehicle .box select{
        margin: .8em 0em;
    }
}

@media only screen and (min-height: 740px) {
    .select-vehicle .car-image img {
        max-width: 160px;
    }

    .select-vehicle .box select{
        margin: 1em 0em;
    }
}

@media only screen and (min-device-width: 360px) and (min-device-height: 740px) {
    .select-vehicle .box select {
        margin: 0.8em 0em;
    }

    .box select, .carImgColor {
        padding: 1em .8em;
    }
}