.grid {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1.6em;
    margin: 6%;
}

.support-body .dashboard-body {
    position: absolute !important;
}




.grid-2 {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 1.6em;
    margin: 6%;
}
.grid-2 .module{
    background: none
}
.reset-pass{
  color: #3d3d3d;;
  padding: 1em;
  text-align: center;
  font-size: 14px;
  margin: 0px;
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  }

.module {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  -webkit-flex: 1 1 200px;
  flex: 1 1 200px;
  border-radius: 6%;
  animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
  -o-animation: bounceIn 0.7s ease-in-out 0s 1 forwards;
}

.module img {
    max-width: 20%;
    margin: 6%;
}
.module p{
    color: #efefef;
}

.grid-2 img{
  border: 3px solid rgba(61, 61, 61, 0.7);
  border-radius: 36%;
}

.support-body.pad-btm {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.support-body .dashboard-body>a:nth-child(even) [class*='col-'] {
  margin-left: auto;
}
.support-body .dashboard-body>a{
  display: inline-block;
  width: 100%;
}
.support-body #dashboard-body {
  height: 100%;
  overflow-y: auto;
  display: inline-block;
  width: 100%;
  padding: 20px 0 0;
   margin-top: 25%;
}
.support-body #dashboard-body a:last-child .animate-two {
  margin-bottom: 0 !important;
}

.react-confirm-alert-overlay {

    background: #0e0e0e !important;

}

.react-confirm-alert-button-group button {
    background-color: #C42532 !important;
    color: #fff;
    padding: 8px !important;
    font-size: 16px !important;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2) !important;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    outline: none !important;
    border-radius: 7px;
    background: -moz-linear-gradient(-45deg, rgba(154,9,21,1) 0%, rgba(255,15,71,0.88) 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(154,9,21,1) 0%,rgba(255,15,71,0.88) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transform-style: preserve-3d;
    will-change: transform;
    border:none;
    width: 100px !important;


}

.react-confirm-alert-body h1 {
    font-size: 24px !important;
    font-weight: bold !important;
    text-align: center !important;
}

.react-confirm-alert-button-group {
text-align: center !important;
    display: block !important;

}

.react-confirm-alert-body {
    text-align:center !important;
}

/* Media Query Landscape Mode */
  @media screen and (min-width: 320px) and (orientation: landscape) {
    .grid {
        display: flex;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: minmax(150px, auto);
        grid-gap: 0.6em;
        margin: 2%;
    }
    .grid-2 {
        display: flex;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-auto-rows: minmax(200px, auto);
        grid-gap: 0em;
        margin: 2%;
        text-align: center;
        
  } 
}

@media only screen

and (max-device-height : 667px) {

    .support-body #dashboard-body {
        padding: 0px 0 0;
        margin-top: 0%;
    }

}


