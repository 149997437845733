.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transition: all 0.5s ease-out;
}
.modals .inp .label {
  color: #2a2a2b;
  opacity: 0.6
}
@keyframes wobble {
  0% {transform: scale(1);}
  50% {transform: scale(0.9);}
  100% {transform: scale(1);}
}

.modals-main{
  animation: 0.5s ease-in-out 0s 1 wobble;
  -webkit-animation: 0.5s ease-in-out 0s 1 wobble;
  -moz-animation: 0.5s ease-in-out 0s 1 wobble;
  -o-animation: 0.5s ease-in-out 0s 1 wobble;
  animation-delay: 0.5s;
}
.modals-main {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top:35%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  border-radius: 7px;
}

.close-icon{
  position: absolute;
  top: -2%;
  right: 2%;
  width: auto;
  height: 100%;
  text-align: center;
  line-height: 45px;
  color: #C42532;
  cursor: pointer;
  max-height: 35px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modals-main.rgs_form>.col-12 {
  padding: 0 !important;
}
.modals-main.rgs_form .button-wrapper {
  margin-bottom: 0 !important;
}
.modals {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.modals-main.rgs_form  {
  width: 95%;
  margin: auto;
  right: 0;
  left: 0;
  padding-top: 20px;


}

.dashboard-body .messageSection p, .dashboard-body .messageSection label {
  color: #ffffff !important;
}


.close-icon {
  right: 5px;
  top:0;
}
.svg-inline--fa {
  height: 1.5em;
}

.overlay-fixed{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay-fixed section{
  height: auto;
  width: 100%;
}