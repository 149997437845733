.box select {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: 72%;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    border-top:#ffbe45 1px solid;
}
.box select option{
    background: transparent;
    padding: 20px;
    box-shadow: 0px 0px 7px 1px rgb(196,37,50);
}
select[disabled] {
    -webkit-box-shadow: 0px 0px 7px 1px rgb(196,37,50) !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 0px 7px 1px rgb(196,37,50);  /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 0px 7px 1px rgb(196,37,50);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    opacity: .2;
}
.box select option {
    padding: 30px;
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.box select,.carImgColor{
    animation: 0.7s ease-in-out 0s 1 fadeInUp;
    padding: .8em .8em;
    margin: 1em 0em;
}

.carImgColor{
    padding: 10px;
    margin: .8em 0em;
    width: 72%;
    font-size: 16px;
    border-radius: 7px;
    color: #fff;
    border-top: #ffbe45 1px solid;
    background-color: #C42532;
    opacity: .2;
}
.box-4 .carImgColor{
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
}
.box-4 .carImgColor p{
    font-size: 16px;
    text-align: left;
}
.carImgColor-wrapper .slider{
    height: auto;
}

.slider-wrapper {
    height: 69vh;
    position:relative;
}

.slider-scroll {
    height: inherit;
    overflow: auto;
}

.slick-slider .slick-list{
    min-height: 424px !important;
}

.carImgColor-wrapper .track .slide{
    background-size: 150px !important;
}
.carImgColor-wrapper .slider a.previousButton{
    left: -11px;
}
.carImgColor-wrapper .slider a.nextButton {
    right: -11px;
}
.carImgColor .modals{
    animation:none;
    -webkit-animation: none;
    -moz-animation: none;
    background: rgba(0, 0, 0, 0.1);
}
.select-vehicle-modal .modals-main {
    top:6%;
}
.select-vehicle-modal .slider-img{
    max-width: 50%;
}
.carImgColor-wrapper .modals-main{
    position: fixed;
    z-index: 500;
    width: 95%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 0;
    top: 8%;
    box-sizing: border-box;
    transition: all 0.5s ease-out;
    border-radius: 7px;
    right: 0;
    text-align: center;
    margin: 0 auto;
    background-color: #C42532;

}

.carImgColor-wrapper .slider-img{
    max-width: 49%;
}
.slick-slide img {
    display: inherit !important;
}
.carImgColor-wrapper svg:not(:root).svg-inline--fa {
    overflow: visible;
    font-size: 23px;
}

.slide.animateIn, .slide.animateOut{
    -webkit-transition: all 0.25s linear !important;
    -ms-transition: all 0.25s linear !important;
    transition: all 0.25s linear !important;
}

/* CSS for custom vartical slider */
.carImgColor-wrapper .modals-main{
    padding: 30px !important;
}

.carImgColor-wrapper .slider-img {
    margin: 2px auto !important;
}

/* ---------------CSS for custom vertical slider--------------- */
/* ---------------slick-theme.css---------------- */

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    padding: 0px;
    border-radius: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: rgb(196, 37, 50);
}

.slick-next
{
    top: -2%;
}

.slick-prev
{
    bottom: -7%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: rgb(196, 37, 50);
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 16px;
    line-height: 1.2;

    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 48%;
}
[dir='rtl'] .slick-prev
{
    right: 48%;
    left: auto;
}

.slick-prev:before
{
    content: '↓';
}

[dir='rtl'] .slick-prev:before
{
    content: '↓';
}

.slick-next
{
    right: 45%;
}

[dir='rtl'] .slick-next
{
    right: auto;
    left: 45%;
}

.slick-next:before
{
    content: '↑';
}
[dir='rtl'] .slick-next:before
{
    content: '↑';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


/* CSS media-query */

/* iPhone 5/SE in portrait & landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {

    .box select, .carImgColor {
        padding: .5em .8em;
        font-size: 14px;
    }
}

/* iPhone 6, 7, & 8 in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
    .box select, .carImgColor {
        padding: .6em .8em;
        font-size: 14px;
    }

}

/* iPhone 6, 7, & 8 Plus in portrait & landscape */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
    .box select, .carImgColor {
        padding: .8em .8em;
        font-size: 15px;
    }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .box select, .carImgColor {
        padding: .8em .8em;
    }
}


@media only screen and (min-height: 740px) {
    .box select, .carImgColor {
        padding: 1em .8em;
        font-size: 15px;
    }
}

.user-filter select {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    border-top:#ffbe45 1px solid;
    margin-bottom: 30px;
}

.account-list li {
    background-color: #C42532;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    margin-bottom: 4px;
}

.account-list li:hover {
    background-color: #FFF;
    color: #C42532;
    cursor: pointer;
}

.account-list {
    margin-top: 90px;
}

.account-list ul {
    list-style: none;
    margin-top: 10px;
    padding-left: 0;
    height: calc(100vh - 200px);
    overflow: auto;
}

.account-list input::placeholder {
    color: #7c7c7c
}

.input-box {
    display: flex;
}

.input-box input {
    flex-grow: 1;
}

.user-filter label, .account-list label {
    color: white;
    margin-right: 12px;
}


