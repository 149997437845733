.not-valid{
    text-decoration: none;
}
.red {
    color: #C42532 !important;
}
.login-body{
    position: relative;
} 
.login-body .login-inner{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    height: 100%;
    vertical-align: middle;
    bottom: 0
}
.login-body .password-info{
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    -webkit-box-shadow: 0 0px 6px #777;
    -moz-box-shadow: 0 0px 6px #777;
    box-shadow: 0 0px 6px #777;
    text-align: center;
}
.login-body .password-info .pass-info-up span{
    display: inline-block;
    font-size: 12px;
    color: #9098a9;
}  
.login-body .password-info .pass-info-up p{
    display: inline-block;
    font-size: 12px;
    color: #9098a9;
} 
.login-body .password-info .pass-info-up span{
    font-size: 12px;
    border-bottom: 1px solid #fff;
}
.login-body .not-email{
    color: #fff !important;
}

select.select-account {
    width: 100%;
}

/* Media Query Landscape Mode */
@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .login-body {
        position: relative;
    } 
  }

  @media screen and (max-device-height: 350px){

  }

.login-body .password-info{
    position: relative;
}
