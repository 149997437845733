.cropper-container {
    margin-left: auto;
    margin-right: auto;
}

.btn-rotation {
    margin-bottom: 40px;
}

.adjustPhoto {
    width: 100%;
    padding-top: 12px;
}
