.listview-body::after{
  display: none;
}
.history-body .listview-body p.his-title{
  padding: 5px 0px 0px 0px;
}
.history-body .tab-hor{
  margin-top: 1em;
}

.wrapper-history {
  color: #fff;
  padding-bottom: 0;
  padding-top: 0;
}

.wrapper-history .profile {
  text-align: center;
  padding: 10px 10px 30px;
  background: #000;
}

.wrapper-history .profile .pic-wrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
}

.wrapper-history .profile .pic-wrapper img {
  max-width: 100%;
}

.wrapper-history .profile .pic-wrapper p {
  font-weight: 700;
  text-transform: capitalize;
}

.wrapper-history .profile p {
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px 0 15px;
}

.wrapper-history .pro-data {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #282c34;
  border-left: 0;
  border-right: 0;
  background: #000;
  height: auto;
}

.wrapper-history .pro-data span {
  display: block;
  color: #ddd;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
}

.wrapper-history .pro-data>div {
  padding: 5px;
  font-weight: 700;
  font-size: 1.3rem;
}

.wrapper-history .thumb ul li {
  list-style: none;
  margin: 10px;
  padding: 0;
  border: 1px solid #494949;
}

.wrapper-history .thumb ul li .img-wrapper {
  height: 105px;
  width: 105px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.wrapper-history .thumb ul {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.wrapper-history .thumb ul li .relative {
  position: relative;
  cursor: pointer;
}

.wrapper-history .thumb ul li img {
  max-width: 100%;
}

.wrapper-history .thumb {
  padding: 10px;

  overflow: scroll !important;

}

.wrapper-history .thumb ul li span.label {
  position: absolute;
  right: 0;
  display: block;
  padding: 2px 3px 2px 5px;
  top: 5px;
  border-radius: 10px 0 0 10px;
  font-size: 0.5rem;
  font-weight: 700;
  background: #000;
  text-transform: uppercase;
  color: #fff;
}

.wrapper-history .thumb ul li span.label.green-lbl {
  background: #23a101;
}
.wrapper-history .thumb ul li span.label.blue-lbl {
  background: #89cff0;
}
.wrapper-history .thumb ul li span.label.orange-lbl {
  background: #E86100;
}

.wrapper-history .thumb ul li span.label.red-lbl {
  background: #c01f23;
}

.wrapper-history .thumb ul li .txt-thumb {
  background: #c3c6c8d9;
  color: #494949;
  font-size: 0.7rem;
  padding: 2px 2px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper-history .thumb ul li .dt-thumb {
  position: absolute;
  bottom: 22px;
  background: #d2f1c9;
  padding: 2px 3px;
  font-size: 0.6rem;
  right: 0;
  color: #282c34;
  font-weight: 700;
}

.wrapper-history {
  padding-top: 0;
}
.wrapper-history .profile {
  padding: 20px 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.wrapper-history .thumb ul li {
  margin: 15px 0 0 7.5px;
}
.wrapper-history .thumb {
  padding-top: 0;
}
.wrapper-history .thumb ul {
  padding: 0 10px 0 0;
  justify-content: center;
}

.fixed-top{
  position: fixed;
  top: 80px !important;
  right: 0;
  left: 0;
  width: 100%;
  height: 140px;
}

.wrapper-history {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
}

.wrapper-history .thumb {
  padding-top: 140px;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 120px;
  z-index: 1;
}

.fadeBG{
  background: url(../../Assets/fadeBG.png);
  background-repeat: no-repeat;
  z-index: -1;
  position: fixed;
  height: 132px;
  width: 100%;
  bottom: 40px;
  top: auto;
  opacity: 0.8;
}

@media only screen and (max-width:380px) {
  .wrapper-history .thumb ul li .img-wrapper {
    height: 95px;
    width: 95px;
  }

  .fadeBG{
    bottom: 40px;
  }
}

@media only screen and (max-width:320px) {
  .wrapper-history .thumb ul li .img-wrapper {
    height: 75px;
    width: 75px;
  }
  .wrapper-history .thumb ul li span.label {
    font-size: 0.5rem;
  }
  .wrapper-history .thumb ul li .txt-thumb {
    font-size: 0.5rem;
  }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  .post-body {
    height: auto;
  }

  .display-block {
    display: block;
    height: auto;
  }

  .fixed-top {
    top: 66px!important;
  }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  .post-body {
    height: auto;
  }

  .display-block {
    display: block;
    height: auto;
  }

  .fixed-top {
    top: 66px!important;
  }

  .fadeBG{
    bottom: 40px;
  }
}