
#header{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 999;
  background: #000;

}

.network {
    background-color: #ffbe45;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    padding: 5px 0px;
}

.network img {

    float: left;
    margin-left: 20px;
    margin-top: -5px;
    margin-right: 10px;
}

.header-body{
    text-align: center;
}

.header-body p{
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 2px solid #a1bbc8;
    opacity: 0.8;
}
.backbtn{
  width: 34px;
  height: 34px;
  float: left;
  position: absolute;
  left: 4%;
  border-radius: 50%;
  }
  .helpbtn{
    border-radius: 0%;
  }
  .logoutbtn{
    float: right;
    right: 4%;
    left: auto;
    border-radius: 0%;
  }

  
/* ----------------------CSS Media-query------------------- */
 /* iPhone 5/SE in portrait & landscape */
 @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  #header {
    height: 66px;
  }
}

  /* iPhone 6, 7, & 8 in portrait & landscape */

  @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
    #header {
      height: 66px;
    }
  }
  
  /* iPhone 6, 7, & 8 Plus in portrait & landscape */
  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) { 
    #header {
      height: 66px;
    }
  }

  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) { 
    
  }