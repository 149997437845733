.users-body{
    margin-top: 2em;
}

.listview-body-wrapper {
    margin: 0;
    padding-top: 100px;
}

.users-body .listview-body p.his-title{
    padding: 5px 0px 0px 0px;
    font-wieght: bold !important;
    font-size: 16px !important;
    text-transform: capitalize;
    color: #ffffff !important;
    text-shadow: 1px 1px #000000;
}

 .users-body img{
    background-color: transparent !important;
    border: none !important;
     box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.5);


}

 .users-body .listview-arrow img  {


     box-shadow: none !important;


 }
.users-body .img {

    border: none !important;

}

.users-body .listview-body  {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7d7e7d+0,5b5b5b+22,0e0e0e+83 */
    background: #7d7e7d; /* Old browsers */
    background: -moz-linear-gradient(top,  #7d7e7d 0%, #5b5b5b 22%, #0e0e0e 83%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #7d7e7d 0%,#5b5b5b 22%,#0e0e0e 83%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #7d7e7d 0%,#5b5b5b 22%,#0e0e0e 83%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e',GradientType=0 ); /* IE6-9 */

}

.users-body  .listview-body-rejected {
    border-top: 1px solid #ffc107 !important;
    text-shadow: 1px 1px darkred;
    text-transform: capitalize;

}

.listview-body-wrapper.pad-btm {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
    height: 100%;
    overflow: scroll !important;
}
.listview-body-wrapper .tab-hor:last-child .listview-body {
    margin-bottom: 10px;
}

