.error-body .post {
    background-color: #F9FAFB;;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  }
  .error-body .sad404{
    max-width: 15%;
 }
  .error-body .post .profile-logo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 4rem;
  }
  .error-body .post .profile-logo img {
    width: 10rem;
    height: 10rem;
    transform: translateY(35%);
  }
  
  .error-body .post h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 20px;
  }
  .error-body .post .actions .follow-btn button{
    color: #fff;
    font: inherit;
    font-weight: bold;
    background-color: #C42532;
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1rem/50%;
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .error-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

/* Media Query */
  @media screen 
  and (min-width: 320px) 
  and (max-width: 450px){
    .error-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }

  @media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
    .error-body{    
      padding: 2rem 1rem 6rem 1rem;
   }
  }
/*Pixel 2XL */
  @media screen and (device-width: 411px) and (device-height: 823px){
    .error-body{    
      padding: 5rem 1rem 6rem 1rem;
   }
  }
/*iPhone X */
  @media screen and (device-width: 375px) and (device-height: 812px){
    .error-body{    
      padding: 4rem 1rem 6rem 1rem;
   }
  }