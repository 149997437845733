@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }
  .optPhoto-btn{
    padding: 3rem 0rem 3rem 0rem;
  }
  .optionalimg{
    margin: 0 auto;
  }
.oprtionalPhoto-wrapper .optionalPhoto{
    box-shadow:none;
    background-color: #C42532;
    border-top:#ffbe45 1px solid;
    border-radius: 7px;
    color: #fff;
    padding: 8px;
    width: 72%;
    font-size: 12px;
    -webkit-appearance: listitem;
    -moz-appearance: listitem;
    appearance: listitem;
    outline: none;
    animation: 0.7s ease-in-out 0s 1 fadeInUp;
    padding: 1em 1em;
    margin: 1em 0em;
    margin: 0 auto;
  }
  .optionalPhoto{
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
  }
  .optionalPhoto p{
    font-size: 12px;
    text-align: center;
  }
  .oprtionalPhoto-wrapper label{
    padding: 10px;
    background: transparent;
    display: table;
    color: #fff;
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
    text-align: center;
  }
  .oprtionalPhoto-wrapper input[type="file"] {
      display: none;
  }
  .oprtionalPhoto-wrapper .showImg{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  .oprtionalPhoto-wrapper img {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    max-width: 70%;
    border: 5px solid #efefef;
    background: #efefef;
    margin-bottom: 1rem;
    max-height: 320px;
}

  .oprtionalPhoto-wrapper .choose-photo{
    padding: 0 0rem 1rem 0rem;
  }

  .oprtionalPhoto-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .messageSection{
    padding: 5px;
  }

  .messageSection .textCenter label {
    color: #000000;
    font-size: 20px;
  }

  .messageSection .textCenter label input {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .messageSection .textCenter {
    text-align: center;
  }

  .messageSection .textCenter .btn {
    color: #fff;
    margin-top: 1rem;
  }

  .messageSection .textCenter h5{
    color: #C42532;
    font-weight: bold;
    font-size: 22px;
  }

  .messageSection .textCenter p{
    color: #000000;
    font-size: 17px;
  }