.single-user {
  background-color: rgba(61, 61, 61, 0.5);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 2rem;
  box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
  border-radius: 2%;
}

.users-body .listview-body .img img {

  border-radius: 50% !important;
  padding: 3px;

}

.listview-body .users-body img {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0px 10px 0px 0px rgba(0,0,0,0.5);

}

.single-user .banner {
  height: 5.55rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 20px;
}
.single-user .banner img {
  height: 75px;

}
.single-user h3.name {
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
  font-size: 22px;
  color: #efefef;
  text-transform: capitalize;
}
.single-user .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
}
.single-user .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
}
.single-user .actions p{
  color: #ccc6c6;
  text-align: justify;
}
.single-user .module{
  background:none;
  flex: 1 1 35px;
}
.single-user .slow  .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
.single-user button.trans{
  background-color: transparent;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 1);
}
@keyframes fadeOutDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.single-user-body{  
  animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
}



/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .single-user-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .single-user-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .single-user-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}
/*iPhone X */
@media screen and (device-width: 375px) and (device-height: 812px){
  .single-user-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}
.single-user .auto-approved h5{
  color: #efefef;
  float: left
}
.single-user .switcher{
  float: right !important;
}

.single-user div.checkbox.switcher label input, div.radio.switcher label input {
  display: none;
}
.single-user div.checkbox.switcher label input + span, div.radio.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 56px;
  height: 28px;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.single-user div.checkbox.switcher label input + span small, div.radio.switcher label input + span small {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 0;
}
.single-user div.checkbox.switcher label input:checked + span, div.radio.switcher label input:checked + span {
  background: #C42532;
  border-color: #C42532;
}
.single-user div.checkbox.switcher label input:checked + span small, div.radio.switcher label input:checked + span small {
  left: 50%;
}


.single-user .tab-btn-inner .button-wrapper{
  width: 100%;
}
.single-user .tab-btn-inner .button-wrapper .buttonCls{
  border-radius: 0px;
  background-color: transparent;
  background:transparent;
}
.single-user .tab-btn-inner .btn-active{
  background-color: #C42532 !important;
}
.single-user .tab-btn-inner .btn-border{
  border: 2px solid #C42532 !important;
}

.container.single-user-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  overflow-y: auto;
}

/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){

  .single-user div.checkbox.switcher label input + span, div.radio.switcher label input + span {
    width: 44px;
    height: 22px;
    margin-right: 0;
  }

}

@media only screen and (max-device-width: 568px) and (min-device-width: 374px){
  
}