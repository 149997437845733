.about {
  background-color: rgba(61, 61, 61, 0.5) !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    border-radius: 2%;
  color: #ffffff;
  }

  .about .banner {
    height: 5.55rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 20px;

  }
  .about .banner img {
    height: 55px;
  }
  .about .about-title{
    margin-top: 1em;
  }
  .about img.address-info{
    max-width: 70%;
  }
  .about h2.name {
    text-align: center;
    padding: 0 2rem 0.5rem;
    margin: 0;
    font-size: 14px;
  }
  .about .grid {
  display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: minmax(120px, auto);
    grid-gap: 1.6em;
    margin: 6%;
  }
  .about .title {

    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .about .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
  }
  .about .copyr{
    font-size: 12px;
    color: #ffffff !important;
  }
  .about .actions .follow-info h2 {
    text-align: center;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  .about .actions .follow-info p {
    color:#3d3d3d;
    font-size: 17px;
    font-weight: normal;
    font-weight: 600;
    text-align: center;
  }
  
  .about .desc {
    text-align: justify;
    padding: 1rem;
    order: 100;
    text-align: center;

  }
  .about .follow-phone{
    box-shadow: none;
  }
  .about .desc ul{
    list-style: none;
    padding-left: 0px;
  }
  .about .desc ul li{
    padding:0.3em;
  }
  .about .desc p{
    color: #3d3d3d;
    padding:0.3em;
    text-align: center;
  }

  @keyframes fadeOutDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .about-body{  
    animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -webkit-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -moz-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
    -o-animation: fadeOutDown 0.7s ease-in-out 0s 1 forwards;
  }

.container.about-body{
  padding-top: 0;
  padding-bottom: 0;
  height:100%;
  overflow-y:auto;
}
.about-body .module {
  margin-bottom: 25px;
  height: auto;
}
.about .about-title {
  margin-top: 0;
}
.about .follow-phone {
  padding: 0;
  padding-bottom: 20px;
}

.about .actions {
  padding: 0 2rem .4rem;
}
  .about .desc p, .about .desc ul {
    margin: 0;
    padding: 0;
  }
  .about .desc ul li {
    padding: 0;
  }
.module.buttonCls {
  -webkit-appearance: none;
  padding-top: 5px;

}

.module.buttonCls a {
  display: inline-block;
  width: 20%;
  margin: 6%;
}
.module.buttonCls img {
  max-width: 100%;
  margin: 0;
}
.about .about-title {
  margin: 0 0 5px;
}

  .buttonCls img.telephone:hover{
  filter:invert(100%);
  }

  .buttonCls img.email:hover{
    filter:invert(100%);
}

.desktopView-email p,
  .desktopView-telephone p {
      display: none;
  }


/* Media Query */
@media screen 
and (min-width: 320px) 
and (max-width: 450px){
  .about-body{    
    padding: 2rem 1rem 6rem 1rem;
  }

  .about .banner {
    height: 4rem;
    margin-bottom: 10px;
  }

  .about .banner img {
    height: 45px;
  }

  .about .title {
    padding: 0 2rem 0rem;
  }

  .about .desc ul li {
  font-size: 14px;

  }

  .acc {
    font-weight: bold !important;
    color: #ffc107 !important;
    font-size: 20px !important;
  }

  .about-body .module {
  margin-bottom: 15px;
  }

  .about .desc {
    padding: .5rem;
  }

  .module.buttonCls a {
    width: 18%;
  }

  .about .actions {
    padding: 0 2rem .2rem;
  }

  .about .about-title {
    margin: 0 0 0px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-height: 800px) and (orientation: landscape) {
  .about-body{    
    padding: 2rem 1rem 6rem 1rem;
 }
}
/*Pixel 2XL */
@media screen and (device-width: 411px) and (device-height: 823px){
  .about-body{    
    padding: 5rem 1rem 6rem 1rem;
 }
}

/*iPhone X */

@media screen and (device-width: 375px) and (device-height: 812px){
  .about-body{    
    padding: 4rem 1rem 6rem 1rem;
 }
}

@media screen and (min-width: 676px) {

  .container.about-body {
    width: 60%;
  }

  .about .banner{
    height: 3rem;
    margin-bottom: 5px;
  }

  .about .banner img{
    height: 80%;
  }

  .about h2.name {
    font-size: .8rem;
    padding: 0;
  }

  .about .title {
    font-size: 0.80rem;
    padding: 0.1rem 2rem 0.1rem;
}

.about .desc ul li{
  font-size: .8rem;
}

.about-body .module {
  margin-bottom: 20px;
}

.about .about-title{
  margin: 0;
}

.about .desc {
  padding: 0;
}


.module.buttonCls .desktopView-email{
  width: 58%;

}

.module.buttonCls .desktopView-telephone{
  width: 30%;
}

.module.buttonCls a {
  text-align: center;
    margin: 1% 3%;
}

.module.buttonCls .desktopView-email img.email{
  width: 26%;
  margin-bottom: .1rem;
}

.module.buttonCls .desktopView-telephone img.telephone{
  width: 40%;
  margin-bottom: .5rem;
}

  .module.buttonCls a:hover {
    text-decoration: none;
  }
  
  .desktopView-email p,
  .desktopView-telephone p {
      font-size: 12px;
      display: block;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .desktopView-email p:hover,
  .desktopView-telephone p:hover {
      color: #000;
      transition: 0.3s ease-in-out all;
      text-decoration: none;
  }

}