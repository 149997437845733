#loader-dim{
  background: rgba(0,0,0,.5);
  position:fixed;
  top:0;
  right: 0;
  left:0;
  bottom: 0;
  z-index:999;
  overflow: hidden;
  }

  #loader-dim .loader{
    background: url(../../Assets/loader-2.svg) no-repeat;
    top: 50%;
    left: 46%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
}
  